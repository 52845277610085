import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navbar, Nav } from 'react-bootstrap'
import * as rs from '../../utils/resource'
import './index.scss'
import { withRouter } from '../../utils/hook'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bToggle: false
    }
    this.resizeHeaderOnScroll = this.resizeHeaderOnScroll.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.anchorChanged = this.anchorChanged.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.resizeHeaderOnScroll)
    window.addEventListener('resize', this.updateWindowDimensions)
    window.addEventListener('hashchange', this.anchorChanged)
    window.onbeforeunload = function(e) {
      console.log('Dialog text here.')
    }
  }

  anchorChanged() {
    console.log('anchorChanged')
    window.scrollTo(window.scrollX, window.scrollY - 100)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    if (window.innerWidth > 991 && this.state.bToggle == true) {
      this.setState({ bToggle: false })
    }
  }

  handleClickMenu = link => {
    this.props.router.history(link)
    this.setState({ bToggle: false })
  }

  resizeHeaderOnScroll() {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 90,
      navbar = document.querySelector('.navbar')

    if (!navbar) return
    if (distanceY > shrinkOn) {
      navbar.classList.add('navbar-white-bg')
    } else {
      if (navbar.classList.contains('navbar-white-bg')) navbar.classList.remove('navbar-white-bg')
    }
  }

  handleToggle = bToggle => {
    this.setState({ bToggle })
  }

  render() {
    const menus = rs.menus
    const currentRoute = this.props.router

    console.log(currentRoute.location.pathname)

    if (['/privacy-policy', '/terms-of-service'].includes(currentRoute.location.pathname)) return ''

    return (
      <Navbar collapseOnSelect expand="lg" variant="light" expanded={this.state.bToggle} onToggle={this.handleToggle}>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Brand onClick={() => this.handleClickMenu('')}>
          <img className="navbar-brand-white" src="/assets/images/Logo-white.png" />
          <img className="navbar-brand-black" src="assets/images/Logo-black.png" />
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            {menus.map((menu, index) => {
              if (currentRoute.location.pathname === `/${menu.val}`) {
                return (
                  <Nav.Link key={index} onClick={() => this.handleClickMenu(menu.path)}>
                    <strong>{menu.label}</strong>
                  </Nav.Link>
                )
              }

              return (
                <Nav.Link key={index} onClick={() => this.handleClickMenu(menu.path)}>
                  {menu.label}
                </Nav.Link>
              )
            })}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

Header.propTypes = {}

Header.defaultProps = {}

export default withRouter(Header)
