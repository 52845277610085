import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import * as rs from "../../utils/resource";
import FooterComp from "../../components/Footer";
import OzContactBar from "../../components/OzContactUsBar";
import OzHero from "../../components/OzHero";
import OzContactUsModal from "../../components/OzContactUsModal";
import OzRectMenu from "../../components/OzRectMenu";
import IframeResizer from "iframe-resizer-react";

class PageBlogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      height: 0,
    };
    this.iframe = createRef();
  }

  componentDidMount() {}

  toggleContactUsModal = (val) => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };

  onBlogsLoaded() {
    console.log(this.iframe.current.contentDocument);
  }

  render() {
    return (
      <>
        {/* <OzFirstScreenSolutions /> */}
        <OzHero
          hero={rs.first_screens.blogs}
          callbackModal={this.toggleContactUsModal}
        />
        <IframeResizer
          heightCalculationMethod="lowestElement"
          inPageLinks
          src="https://blogs.oelement.net/"
          // src="http://localhost:5173/"
          onResized={(data) => {
            this.setState({ height: parseInt(data.height) });
          }}
          style={{
            width: "1px",
            minWidth: "100%",
            minHeight: this.state.height + "px",
          }}
        />
        <OzContactBar
          section={rs.contact_us_bar.solutions}
          callbackModal={this.toggleContactUsModal}
        />
        <OzContactUsModal
          modal={rs.contactUsModal}
          confirm={rs.confirmModal}
          callbackModal={this.toggleContactUsModal}
          isModalVisible={this.state.isModalVisible}
        />
        <FooterComp />
      </>
    );
  }
}

PageBlogs.propTypes = {};

PageBlogs.defaultProps = {};

export default PageBlogs;
