import React, { Component } from "react";
import "./index.scss";
import OzSectionComment from "../SectionComment";
import OzSectionBottomItems from "../SectionBottomItems";
class OzPageSolutionsSection extends Component {
  render() {
    const { section, id } = this.props;

    const {
      title,
      description,
      background,
      center_img,
      factor_logo,
      left_img,
      right_img,
      top_img,
      comment,
      items
    } = section;

    return (
      <>
        <div className="oz-page-solutions-section-id" id={id.toLowerCase()} />
        <div
          className="oz-page-solutions-section"
          style={{ backgroundImage: `url(${background})` }}
        >
          <div className="oz-page-solutions-section__top-left">
            <h3 className="oz-page-solutions-section__top-left__title">
              {title}
            </h3>
            <div className="oz-page-solutions-section__top-left__bg" />
          </div>
          <div className="oz-page-solutions-section__content">
            <div className="oz-page-solutions-section__content__row oz-page-solutions-section__content__row-second">
              <img
                className="oz-page-solutions-section__content__bubble-img"
                src={left_img}
              />
              <img
                className="oz-page-solutions-section__content__bubble-img"
                src={top_img}
              />
              <img
                className="oz-page-solutions-section__content__bubble-img"
                src={right_img}
              />
            </div>
            <div className="oz-page-solutions-section__content__row">
              <img
                className="oz-page-solutions-section__content__center-img"
                src={center_img}
              />
            </div>
            <h4>{description}</h4>
          </div>

          <OzSectionComment comment={comment} logo={factor_logo} />
        </div>
        <OzSectionBottomItems items={items} />
      </>
    );
  }
}

OzPageSolutionsSection.propTypes = {};

OzPageSolutionsSection.defaultProps = {};

export default OzPageSolutionsSection;
