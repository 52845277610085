import React, { Component } from 'react'

import * as rs from '../../utils/resource'

import OzSolutions from './Section/OzSolutions'
import OzWhyChooseUs from './Section/OzWhyChooseUs'
import OzServices from './Section/OzServices'
import FooterComp from '../../components/Footer'
import OzSolutionMenu from './Section/OzSolutionMenu'
import OzTestimol from './Section/OzTestimol'
import OzCoverFlow from './Section/OzCoverFlow'

import OzContactBar from '../../components/OzContactUsBar'
import OzHero from '../../components/OzHero'
import OzHomeSimplify from './Section/OzHomeSimplify'
import OzContactUsModal from '../../components/OzContactUsModal'
import OzNotification from './Section/OzNotification'

import './index.scss'

class MainPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalVisible: false
    }
  }

  componentDidMount() {}

  toggleContactUsModal = val => {
    this.setState({ isModalVisible: !this.state.isModalVisible })
  }

  render() {
    return (
      <>
        <OzHero hero={rs.first_screens.home} callbackModal={this.toggleContactUsModal} />
        {/* <OzCarousel carousels={rs.home_carousel} /> */}
        {/* <OzApproach {...rs.approach}/> */}
        <OzTestimol />
        <OzNotification />
        <OzSolutions {...rs.solutions} />
        <OzSolutionMenu menus={rs.solutions_menus} />
        <OzHomeSimplify className="oz-main-page-simplify" section={rs.simplify} />
        <OzCoverFlow coverflow={rs.coverflow} />
        {/* <OzSimplifyUpdate {...rs.simplify} /> */}
        {/* <OzSimplifyUpdatev2 {...rs.simplify} /> */}
        <OzServices {...rs.service_section} />
        {/* <OzApproach title="Our Products"/> */}
        {/* <OzQuote {...rs.quote}/> */}
        {/* <OzGallery /> */}
        <OzWhyChooseUs {...rs.why_choose_us} />
        {/* <OzKPI KPI={rs.KPI} /> */}
        {/* <OzMultiChannels channel_section={rs.channel_section}/> */}
        {/* <OzServices service_section={rs.service_section}/> */}
        {/* <OzContactUs contact_form={rs.contact_form}/> */}

        <OzContactBar section={rs.contact_us_bar.home} callbackModal={this.toggleContactUsModal} />
        <OzContactUsModal
          modal={rs.contactUsModal}
          confirm={rs.confirmModal}
          callbackModal={this.toggleContactUsModal}
          isModalVisible={this.state.isModalVisible}
        />
        <FooterComp />
      </>
    )
  }
}

MainPage.propTypes = {}

MainPage.defaultProps = {}

export default MainPage
