import React, { Component } from "react";
import { connect } from "react-redux";
import './index.scss'
class OzSolutionMenu extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const {menus} = this.props
    return (
      <section className="oz-solution-menu">
        <div className="row">
          {menus.map((menu, index) =>
          <a className="oz-solution-menu__item" key={index} href={`/solutions#${menu.title.split(' ').join('-').toLowerCase()}`}>
            <div className="oz-solution-menu__item__mask"/>
            <div className="oz-solution-menu__item__overlay"/>
            <h2 className="oz-solution-menu__item__title">{menu.title}</h2>
            <h4 className="oz-solution-menu__item__subtitle">{menu.sub_title}</h4>
            {/*<img className="oz-solution-menu__item__cover" src={menu.imgURL}/>*/}
          </a>)}
        </div>
      </section>
    )
  }
}

OzSolutionMenu.propTypes = {
};

OzSolutionMenu.defaultProps = {
  className: ""
};

export default OzSolutionMenu
