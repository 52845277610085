export const formatDate = (date) => {
  var months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return date.toString().slice(0, 3) + ' ' + months[monthIndex] + ' ' + (day < 10 ? '0' : '') + day + ', ' + year;
}