import React, { Component } from "react";
import './index.scss'

class CobaltOzContactBar extends Component {
  constructor(props) {
    super(props);
    this.updateDate = '';
  }

  render() {
    const {section} = this.props
    return (
      <div className="cobalt-oz-contact-bar-container">
        <img className="cobalt-oz-contact-bar-back" src={section.background} alt="" />
        <div className="cobalt-oz-contact-bar-body">
          <div className="cobalt-oz-contact-bar-p">
            <a href="mailto:sysadmin@openznet.com" target="_blank"><p>Join our</p>
              <img className="cobalt-oz-contact-bar-img" src={section.factor_logo} alt="" />
              <p>Circle community </p>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default CobaltOzContactBar;
