import {
    useLocation,
    useNavigate,
    useParams,
  } from "react-router-dom";
  
export const withRouter = Component => {
    const ComponentWithRouterProp = (props) => {
      let location = useLocation();
      let history  = useNavigate();
      let params = useParams();
      return (
        <Component
          {...props}
          router={{ location, history, params }}
        />
      );
    }
  
    return ComponentWithRouterProp;
}