import React, { Component } from "react";
import "./index.scss";
class OzSectionBottomItems extends Component {
  render() {
    const { items } = this.props;

    return (
      <div className="oz-component-section-bottom-items row">
        {items.map((item, index) => (
          <div
            className="oz-component-section-bottom-items__item col-md-4"
            key={index}
          >
            <div className="section-bottom-check-mark-and-text">
              <div
                dangerouslySetInnerHTML={{ __html: item.check_mark }}
                className="solutions-check-mark"
              />
              <span>{item.title}</span>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

OzSectionBottomItems.propTypes = {};

OzSectionBottomItems.defaultProps = {};

export default OzSectionBottomItems;
