import React, { Component } from "react";
import { connect } from "react-redux";
import OzHeroProducts from "../../components/FirstScreens/Products";
import * as rs from "../../utils/resource";
import OzPageProductsSection from "./Section";
import OzPageProductsSectionLeft from "./SectionLeft";
import ProductMenu from "./menu";
import OzContactBar from "../../components/OzContactUsBar";
import FooterComp from "../../components/Footer";
import OzContactUsModal from "../../components/OzContactUsModal";
import OzProducts from "./Section/OzProducts";

class PageProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false
    };
  }

  componentDidMount() {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf('#') + 1
    );
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  toggleContactUsModal = val => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };

  render() {
    const { sections } = rs.products_page;
    return (
      <>
        <OzHeroProducts
          hero={rs.first_screens.products}
          callbackModal={this.toggleContactUsModal}
        />
        <ProductMenu
          menus={rs.products_page.sections.map(section => section.title)}
        />
        {sections.map((section, index) =>
          <OzProducts
            id={section.title}
            key={index}
            section={section}
            ref={section.title} />
          // if (section.type == "right")
          //   return (
          //     <OzPageProductsSection
          //       id={section.title}
          //       key={index}
          //       section={section}
          //       ref={section.title}
          //     />
          //   );
          // return (
          //   <OzPageProductsSectionLeft
          //     id={section.title}
          //     key={index}
          //     section={section}
          //     ref={section.title}
          //   />
          // );

        )}
        <OzContactBar
          section={rs.contact_us_bar.products}
          callbackModal={this.toggleContactUsModal}
        />
        <OzContactUsModal modal={rs.contactUsModal} confirm={rs.confirmModal} callbackModal={this.toggleContactUsModal} isModalVisible={this.state.isModalVisible} />
        <FooterComp />
      </>
    );
  }
}

PageProducts.propTypes = {};

PageProducts.defaultProps = {};

export default PageProducts
