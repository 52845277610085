import React, { Component } from "react";
import './index.scss'


class OzContactBar extends Component {
  constructor(props) {
    super(props);
    this.updateDate = '';
  }

  render() {
    const {section} = this.props
    return (
      <div className="oz-contact-bar-container">
        <img className="oz-contact-bar-back" src={section.background} alt="" />
        <div className="oz-contact-bar-body">
          <div className="oz-contact-bar-p">
            <p><a href="mailto:info@openznet.com" target="_blank"><u>Contact us</u></a> to see our</p>
            <img className="oz-contact-bar-img" src={section.factor_logo} alt="" />
            <p>Factor! </p>
          </div>
        </div>
      </div>
    );
  }
}

export default OzContactBar
