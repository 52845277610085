import React, { Component } from "react";
import './index.scss'
class OzTestimol extends Component {

  render() {
    const {} = this.props
    return (
      <section className="oz-testimol">
        <div className="row oz-testimol__footer">
          <div className="oz-testimol__footer__left">
            <h1>Why we do it</h1>
          </div>
          <div className="oz-testimol__footer__right">
            <p>As a small business, we appreciate the dedication needed for top-quality service and growth. We’re passionate about supporting fellow businesses, making the journey smoother and more rewarding</p>
          </div>
        </div>
      </section>
    )
  }
}

export default OzTestimol
