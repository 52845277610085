import React from 'react';
import AppNoAuth from './AppNoAuth';
import configureStore from "./store";

class AppWithoutAuth extends React.Component {
  render() {
    return (
      <div>
        <AppNoAuth />
      </div>
    );
  }
}

export default AppWithoutAuth;
