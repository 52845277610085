import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss'

class OzIntro extends Component {
  render() {
    const { sections } = this.props;
    // const { img, title, description } = section;
    return (
      <section className="oz-aboutus-intro">
        {sections.map((section, index) => (
          <div className="oz-aboutus-intro-item" key={ index }>
            <div className="oz-content">
              <div className="oz-content__header">
                <img src={section.img} />
              </div>
              <h3 className="oz-content__title">{section.title}</h3>
              <h4 className="oz-content__description">{section.description}</h4>
            </div>
          </div>
        ))}
      </section>
    )
  }
}

export default OzIntro