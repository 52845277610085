import React, { Component } from 'react'
import { Route, Navigate, BrowserRouter as Router, Routes } from 'react-router-dom'
import Header from '../components/Header'
import MainPage from '../containers/MainPage'
import PageSolutions from '../containers/PageSolutions'
import PageServices from '../containers/PageServices'
import PageProducts from '../containers/PageProducts'
import PageCobalt from '../containers/PageCobalt'
import PageAboutUs from '../containers/PageAboutUs'
import PagePrivacyPolicy from '../containers/PagePrivacyPolicy'
import PageTermsOfService from '../containers/PageTermsOfService'
import PageOcircle from '../containers/PageOcircle'
import PageBlogs from '../containers/PageBlogs'

const MainRoutes = () => (
  <Router>
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Header />
      <Routes>
        <Route
          path="/privacy-policy"
          element={<PagePrivacyPolicy />}
        />
        <Route
          path="/terms-of-service"
          element={<PageTermsOfService />}
        />
        <Route
          path="/cobalt"
          element={<PageCobalt />}
        />
        <Route
          path="/blog"
          element={<PageBlogs />}
        />
        <Route
          path="/products"
          element={<PageProducts />}
        />
        <Route
          path="/solutions"
          element={<PageSolutions />}
        />
        <Route
          path="/services"
          element={<PageServices />}
        />
        <Route
          path="/ocircle"
          element={<PageOcircle />}
        />
        <Route
          path="/about_us"
          element={<PageAboutUs />}
        />
        <Route
          path="/"
          element={<MainPage />}
        />
        <Route render={() => <Navigate to="/" />} />
      </Routes>
    </div>
  </Router>
)

export default MainRoutes
