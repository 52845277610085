import React from 'react'
// import { connect } from "react-redux";
// import { Carousel, Button } from "react-bootstrap";
import './index.scss'

const OzHeroProducts = ({ hero }) => (
  <section
    className="oz-hero-product"
    style={{ backgroundImage: `url(${hero.img})` }}
  >
    <div
      className="oz-hero-product__mask"
      style={{ backgroundColor: hero.mask }}
    />
    <div className="oz-hero-product__content">
      <div className="oz-hero-product__content__logo">
        <img src={hero.logo} alt="logo" />
        <div>
          <h1>ELEMENT</h1>
          <h2>Products</h2>
        </div>
      </div>
      <h2>{hero.sub_title}</h2>
      {hero.show_button === true && (
      <a href="mailto:info@openznet.com" target="_blank">{hero.btn_label}</a>
      )}
    </div>
    {/* <div className="oz-hero-product__footer"> */}
    {/*  <h3>{hero.bottom_title}</h3> */}
    {/* </div> */}
  </section>
)

export default OzHeroProducts
