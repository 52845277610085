import { createActionTypes } from "../utils"

export const REST_API = createActionTypes("REST_API", [
  "GET",
  "GET_ONE",
  "SAVE",
  "PUT",
  "PATCH",
  "DELETE",
  "SUCCESS",
  "FAILURE"
])
export const UI = createActionTypes("UI", [
  "TOGGLE_LEFT_SIDEBAR",
  "UPDATE_LEFT_SIDEBAR_INFO"
])
export const GRAPHQL_API = createActionTypes("GRAPHQL_API", [
  "CONTENTS_GET_REQUEST",
  "CONTENTS_GET_SUCCESS",
  "CONTENTS_GET_FAILURE",
  "SECTIONS_GET_REQUEST",
  "SECTIONS_GET_SUCCESS",
  "SECTIONS_GET_FAILURE",
  "NOTIFICATIONS_GET_REQUEST",
  "NOTIFICATIONS_GET_SUCCESS",
  "NOTIFICATIONS_GET_FAILURE",
  "COLLECTIONS_GET_REQUEST",
  "COLLECTIONS_GET_SUCCESS",
  "COLLECTIONS_GET_FAILURE",
  "MENUS_GET_REQUEST",
  "MENUS_GET_SUCCESS",
  "MENUS_GET_FAILURE",
  "SOURCES_GET_REQUEST",
  "SOURCES_GET_SUCCESS",
  "SOURCES_GET_FAILURE",
  "GALLERIES_GET_REQUEST",
  "GALLERIES_GET_SUCCESS",
  "GALLERIES_GET_FAILURE",
  "PAGES_SAVE_REQUEST",
  "PAGES_SAVE_SUCCESS",
  "PAGES_SAVE_FAILURE",
  "PAGES_GET_REQUEST",
  "PAGES_GET_SUCCESS",
  "PAGES_GET_FAILURE",
  "DOMAINS_GET_REQUEST",
  "DOMAINS_GET_SUCCESS",
  "DOMAINS_GET_FAILURE"
])

export const STATUS_MSG = createActionTypes("STATUS_MSG", ["SET_STATUS_MSG"])
export const SAVE_PAGE_INFO = "SAVE_PAGE_INFO"
export const CHANGE_PAGE_LINK = "CHANGE_PAGE_LINK"
export const RESET_STORE = "RESET_STORE"
export const UPDATE_ROUTE_INFO = "UPDATE_ROUTE_INFO"

export default {
  REST_API,
  GRAPHQL_API,
  STATUS_MSG,
  SAVE_PAGE_INFO,
  CHANGE_PAGE_LINK,
  RESET_STORE,
  UI,
  UPDATE_ROUTE_INFO
}
