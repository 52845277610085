import React, { Component } from "react";
import './index.scss'
class OzServiceItem extends Component {

  render() {
    const {title, description, front_img } = this.props

    return (
      <div className="oz-services-item">
        <div className="oz-services-item-flipper">
          <div className="oz-services-item-flipper__front">
            <img src={front_img} />
            <h3>{title}</h3>
          </div>
          <div className="oz-services-item-flipper__back">
            <div className="oz-services-item__content">
              <a href={`services#${title.split(' ').join('-').toLowerCase()}`}>{title}</a>
              <span>{description}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

OzServiceItem.propTypes = {
};

OzServiceItem.defaultProps = {};

export default OzServiceItem
