import React, { Component } from "react";
import './index.scss'
class OzHomeSimplify extends Component {

  render() {
    const { section } = this.props

    const { title, description } = section

    return (
      <div className="oz-main-page-simplify">
        <div className="oz-content">
          <h3 className="oz-content__title">{title}</h3>
          <h4 className="oz-content__subtitle" >{description}</h4>
        </div>
      </div>
    )
  }
}

OzHomeSimplify.propTypes = {
};

OzHomeSimplify.defaultProps = {
};

export default OzHomeSimplify
