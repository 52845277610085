import React, { Component } from "react";
import { connect } from "react-redux";
import './index.scss'
class OzQuote extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const {title, sub_title, className} = this.props
    return (<div className={"oz-quote " + className}>
      <h3 className="oz-section__title">{title}</h3>
      {sub_title && <p className="oz-section__subtitle">{sub_title}</p>}
    </div>)
  }
}

OzQuote.propTypes = {
};

OzQuote.defaultProps = {
  className: ""
};

export default OzQuote
