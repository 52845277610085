import React, { Component } from "react";
import "./index.scss";

class OzSectionComment extends Component {
  render() {
    const { comment, logo } = this.props;

    return (
      <div className="oz-component-section-comment">
        <div className="oz-component-section-comment__logo">
          <img src={logo} />
          <span>Factor</span>
        </div>
        <h3 className="oz-component-section-comment__title">{comment}</h3>
        <div className="oz-component-section-comment__bg" />
        <div className="oz-component-section-comment__bg-2" />
      </div>
    );
  }
}

OzSectionComment.propTypes = {};

OzSectionComment.defaultProps = {};

export default OzSectionComment;
