import React, { Component } from "react";
import styled from 'styled-components';

import './index.scss'

class OzButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data, ux, onClick } = this.props
    const Button = styled.button`
      color: ${ux.color};
      fontSize: ${ux.fontSize}px;
      border: 3px solid ${ux.backgroundColor};
      &:hover {
        color: ${ux.hoverColor} !important;
        background-color: ${ux.backgroundColor} !important;
      }
    `;

    return (
      <Button type="submit" className="button" onClick={onClick}>{data}</Button>
    )
  }
}

export default OzButton
