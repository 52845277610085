import React, { Component } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import MainRoutes from "./routes/index";
import { createBrowserHistory } from "history";
import configureStore from "./store";
import ApolloClient from "apollo-boost"
import { ApolloProvider } from "@apollo/client"

const client = new ApolloClient({
  uri: "https://backend-dev.oelement.openznet.com/"
})

const history = createBrowserHistory();
const store = configureStore();

class AppNoAuth extends Component {
  render() {
      return (
        <ApolloProvider client={client}>
          <Provider store={store}>
            {/* <Router history={history}> */}
            <MainRoutes />
            {/* </Router> */}
          </Provider>
        </ApolloProvider>
      );
  }
}
export default AppNoAuth;
