import React, { Component } from "react";
import styled from 'styled-components';
import { Col, Modal, Row } from 'react-bootstrap'

import './index.scss'
import OzButton from '../OzButton'

class OzConfirmModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { confirm, onClick, show } = this.props
    const Wrapper = styled.div`
      background-color: ${confirm.ux.backgroundColor} !important;
      border: ${confirm.ux.border} !important;
    `;

    return (
      <Modal
        dialogClassName="oz-confirm-modal"
        show={show}
        onHide={onClick}
        centered
      >
        <Wrapper>
          <Modal.Body>
            <div className="oz-confirm-modal__header">
              <img src={confirm.data.icon} alt="" />
              <h3>{confirm.data.title}</h3>
            </div>
            <h4>{confirm.data.subtitle}</h4>
            <div className="row mt-3">
              <div className="col" />
              <div className="col text-center">
                <OzButton ux={confirm.button.ux} data={confirm.data.btnText} onClick={onClick} />
              </div>
              <div className="col" />
            </div>
          </Modal.Body>
        </Wrapper>
      </Modal>
    )
  }
}

export default OzConfirmModal
