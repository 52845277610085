import React from 'react'
import './index.scss'

const OzHero = ({ hero }) => (
  <section className="oz-hero" style={{ backgroundImage: `url(${hero.img})` }}>
    <div className="oz-hero__mask" style={{ backgroundColor: hero.mask }} />
    <div className="oz-hero__content">
      {hero.title ? <h1>{hero.title}</h1> : ''}
      {hero.sub_title ? <h2>{hero.sub_title}</h2> : ''}
      {hero.description ? <h3>{hero.description}</h3> : ''}
      {hero.quote ? (
        <div className="oz-hero__content__quote">
          {hero.quote}
          <h5>{hero.quote_author}</h5>
        </div>
      ) : ''}
      {hero.show_button === true && (
        <a
          href="mailto:info@openznet.com"
          target="_blank"
        >
          {hero.btn_label}
        </a>
      )}
    </div>
    {/*<div className="oz-hero__footer">*/}
     {/* <h3>{hero.bottom_title}</h3> */}
    {/*</div>*/}
  </section>
)

export default OzHero
