import React, { Component } from "react";
import "./index.scss";

class OzProducts extends Component {
  render() {
    const { section, id } = this.props;

    const {
      type,
      title,
      sub_title,
      text,
      background,
      icon,
      side_img,
      clip_path,
      star_text,
    } = section;

    return (
      <div className="oz-products" id={id.toLowerCase()} ref={id.toLowerCase()}>
        <div className="oz-products-content">
          <h3 className="oz-products-content__title">{title}</h3>
          <h4 className="oz-products-content__subtitle">{sub_title}</h4>
        </div>
        <div className="oz-products-description row">
          <div className="oz-products-description-txt col-12">
            <div
              className="oz-products-description-txt__back"
              style={{
                maskImage: `url(${background})`,
                WebkitMaskImage: `url(${background})`
              }}
            >
              <div />
            </div>
            <span>{text}</span>
          </div>
        </div>
      </div>
    );
  }
}

OzProducts.propTypes = {};

OzProducts.defaultProps = {};

export default OzProducts;
