import React, { Component } from "react";
import { connect } from "react-redux";
import './index.scss'
class OzTestimol extends Component {

  render() {
    const {} = this.props
    return (
      <section className="oz-aboutus-testimol">
        <div className="row oz-aboutus-testimol__footer">
          <div className="col-md-4 oz-aboutus-testimol__footer__left">
            <h1>Our Passion</h1>
          </div>
          <div className="col-md-8 oz-aboutus-testimol__footer__right">
            <p>is to create elegantly designed and easy-to-use software, while adopting the latest proven technologies to make a difference in the day-to-day operation of our clients.</p>
          </div>
        </div>
      </section>
    )
  }
}

export default OzTestimol
