import React, { Component } from "react";
import { connect } from "react-redux";
import * as rs from '../../utils/resource'
import OzPageOcircleSection from "./Section";
import {Button} from 'react-bootstrap'
import FooterComp from '../../components/Footer'
import OzContactBar from '../../components/OzContactUsBar'
import OzHero from "../../components/OzHero";

import './index.scss'
import OzContactUsModal from "../../components/OzContactUsModal";
import OzPageOcircleSectionLeft from './SectionLeft'
import OzRectMenu from '../../components/OzRectMenu'
import CobaltOzContactBar from "../../components/CobaltOzContactUsBar";
import OzQuote from "../../components/OzQuote";

class PageOcircle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
    }
  }

  componentDidMount() {}

  toggleContactUsModal = (val) => {
    this.setState({isModalVisible: !this.state.isModalVisible})
  }

  render() {
    const { quote, sections } = rs.ocircle_page
    const tempMenu = sections.map((section) => section.title)

    return (
      <>
        <OzHero hero={rs.first_screens.ocircle} callbackModal={this.toggleContactUsModal} />
        {/* <OzQuote title={quote.title} className="oz-quote-services"/> */}
        {/* <section className="oz-page-ocircle-sections" style={{backgroundImage: `url(assets/images/Services/Connect.png)`}}> */}
          <OzRectMenu menus={tempMenu}/>
          {sections.map((section, index) => {
            if (section.type === "right")
              return (
                <OzPageOcircleSection
                  id={section.title.split(' ').join('-').toLowerCase()}
                  key={index}
                  section={section}
                  ref={section.title}
                />
              );
            return (
              <OzPageOcircleSectionLeft
                id={section.title.split(' ').join('-').toLowerCase()}
                key={index}
                section={section}
                ref={section.title}
              />
            );
          }
          )}
        {/* </section> */}
        <OzQuote title={quote.title} sub_title={quote.sub_title} className="oz-quote-services"/>
        {/* <OzContactBar section={rs.contact_us_bar.services} callbackModal={this.toggleContactUsModal} /> */}
        <CobaltOzContactBar section={rs.contact_us_bar.ocircle} />
        <OzContactUsModal modal={rs.contactUsModal} confirm={rs.confirmModal} callbackModal={this.toggleContactUsModal} isModalVisible={this.state.isModalVisible} />
        <FooterComp />
      </>
    )
  }
}

PageOcircle.propTypes = {
};

PageOcircle.defaultProps = {};

export default PageOcircle
