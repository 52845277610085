import React from 'react'
import './index.scss'

const OzHomeProducts = ({homeProducts}) => (
  <>
    <section className='oz-home-products-blank'>&nbsp;</section>
    <section className="oz-home-products">
      <div className="oz-home-products-content row">
        {homeProducts.map((item, index) =>
          <div className="col-md-4">
            <div className='oz-home-products-content_image'>
              <img src={item.img} />
            </div>
            <div className='oz-home-products-content_title'>{item.title}</div>
            <hr/>
            <div className='oz-home-products-content_description'>
              {item.description}
            </div>
          </div>
        )}
      </div>
    </section>
  </>
)

export default OzHomeProducts
