import React, { Component } from "react";
import "./index.scss";
class OzPageServicesSection extends Component {
  render() {
    const { section, id } = this.props;

    const {
      title,
      sub_title,
      front_img,
      back_img,
      light_theme,
      left_img,
      side_img,
      clip_path,
    } = section;

    return (
      <>
        <div className="oz-page-services-section-id" id={id} />
        <div className="oz-page-services-section">
          <div className="oz-page-services-section__content">
            <div className="oz-page-services-section__content__banner">
              <div className="oz-page-services-section__content__banner__bg">
                <img
                  className="oz-page-services-section-left__content__title__img"
                  src={left_img}
                />
                <span>{title}</span>
              </div>
            </div>
            <div className="oz-page-services-section__content__body">
              <h3>{sub_title}</h3>
            </div>
          </div>
          <div className="oz-page-services-section__side-img">
            <img src={side_img} style={{
              clipPath: clip_path,
              WebkitClipPath: clip_path,
            }} />
          </div>
        </div>
      </>
    );
  }
}

OzPageServicesSection.propTypes = {};

OzPageServicesSection.defaultProps = {};

export default OzPageServicesSection;
