import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Row, Form, Modal } from 'react-bootstrap'
import axios from 'axios';
import * as rs from "../../utils/resource";
import './index.scss'
import OzButton from '../OzButton'
import OzConfirmModal from '../OzConfirmModal'

// eslint-disable-next-line react/prefer-stateless-function
const initialState =  {
  isSubmitted: false,
  validated: false,
  isSucceed: false,
  industriesData: [],
  interestedInData: [],
  lookingIn: '',
  lookingInList: rs.contactUsModal.lookingIn,
  industriesList: rs.contactUsModal.industries.reduce(
    (options, option) => ({
      ...options,
      [option]: false,
    }),
    {},
  ),
  interestedInList: rs.contactUsModal.interestedIn.reduce(
    (options, option) => ({
      ...options,
      [option]: false,
    }),
    {},
  ),
}

class OzContactUsModal extends Component {

  constructor(props) {
    super(props);
    this.state = initialState

    this.industryCheckBoxChange = this.industryCheckBoxChange.bind(this);
    this.interestedInCheckBoxChange = this.interestedInCheckBoxChange.bind(this);
    this.lookingInCheckBoxChange = this.lookingInCheckBoxChange.bind(this);
  }

  industryCheckBoxChange = e => {
    const { name } = e.target;

    this.setState(prevState => ({
      industriesList: {
        ...prevState.industriesList,
        [name]: !prevState.industriesList[name],
      },
    }))
  }

  interestedInCheckBoxChange = e => {
    const { name } = e.target;

    this.setState(prevState => ({
      interestedInList: {
        ...prevState.interestedInList,
        [name]: !prevState.interestedInList[name],
      },
    }))
  }

  lookingInCheckBoxChange = e => {
    this.setState({ lookingIn: e.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    let industriesData = []
    Object.keys(this.state.industriesList)
      .filter(checkbox => this.state.industriesList[checkbox])
      .forEach(checkbox => {
        industriesData.push(checkbox)
      })
    if (industriesData[industriesData.length - 1] === 'Other:' && event.target.industry_other.value !== '') {
      industriesData[industriesData.length - 1] = event.target.industry_other.value
    }

    let interestedInData = []
    Object.keys(this.state.interestedInList)
      .filter(checkbox => this.state.interestedInList[checkbox])
      .forEach(checkbox => {
        interestedInData.push(checkbox)
      })

    const lookingInData = this.state.lookingInList[this.state.lookingIn.substr(this.state.lookingIn.length - 1)] || [];

    this.setState({
      validated: true,
      isSubmitted: true,
    })

    const formData = {
      operationName: 'upsertInterestForm',
      variables: {
        data: {
          interested_in: event.target.interested_in.value,
          first_name: event.target.first_name.value,
          last_name: event.target.last_name.value,
          email_address: event.target.email_address.value,
          company_name: event.target.company_name.value,
          company_url: event.target.company_url.value,
          industries: industriesData,
          interestedIn: interestedInData,
          lookingIn: lookingInData,
          message: event.target.message.value,
        },
      },
      query: 'mutation upsertInterestForm ($data: JSONObject!) {upsertInterestForm (data: $data) {_id data}}',
    }

    if (event.target.first_name.value !== ''
      // && event.target.last_name.value !== ''
      && event.target.email_address.value !== ''
      && event.target.company_name.value !== ''
      // && industriesData
      // && this.state.interestedInData
      // && lookingInData
      // && this.state.validated
    ) {
      axios
        .post('https://backend-dev.oelement.openznet.com:4001/', formData)
        .then(res => {
          console.log(res)
          this.setState({
            isSucceed: true,
            lookingIn: '',
            validated: false,
          })
        })
        .catch(err => {
          console.log(err)
          this.setState({ isSucceed: false })
        });
    }

  };

  toggleModal = async () => {
    this.setState(initialState)
    this.props.callbackModal()
  }

  render() {
    const { modal, confirm } = this.props
    return (
      <div>
        <Modal
          dialogClassName="oz-contact-us-modal"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.props.isModalVisible}
          onHide={() => this.props.callbackModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {modal.data.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="oz-contact-us-modal__body">
              <Form
                noValidate
                validated={this.state.validated}
                onSubmit={this.handleSubmit}
              >
                <Row>
                  <Col>
                    <Form.Group controlId="formInterestedIn">
                      <Form.Label>Interested in:</Form.Label>
                      <Form.Control name="interested_in" placeholder="" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>First Name*:</Form.Label>
                      <Form.Control name="first_name" required placeholder="" />
                      <Form.Control.Feedback type="invalid">
                        Please enter first name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formLastName">
                      <Form.Label>Last Name:</Form.Label>
                      <Form.Control name="last_name" placeholder="" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email Address*:</Form.Label>
                      <Form.Control name="email_address" required type="email" placeholder="" />
                      <Form.Control.Feedback type="invalid">
                        Please enter email address.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formPhoneNumber">
                      <Form.Label>Phone Number:</Form.Label>
                      <Form.Control name="phone_number" placeholder="" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formCompanyName">
                      <Form.Label>Company Name*:</Form.Label>
                      <Form.Control name="company_name" required placeholder="" />
                      <Form.Control.Feedback type="invalid">
                        Please enter company name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formContactUrl">
                      <Form.Label>Company URL:</Form.Label>
                      <Form.Control name="company_url" placeholder="" />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="formIndustry">
                  <Form.Label className="pl-3">Industry:</Form.Label>
                  <Row>
                    {modal.industries.map((name, index) => (
                      <Col md={6} lg={4} key={index} className={modal.industries.length === index + 1 ? 'oz-contact-us-modal-col__others' : ''}>
                        <label className="oz-contact-us-modal__body-checkbox">
                          <input
                            type="checkbox"
                            id={`industry-${index}`}
                            name={name}
                            onChange={this.industryCheckBoxChange}
                            checked={this.state.industriesList[`industry-${index}`]} />
                          <span />
                          {name}
                        </label>
                        {modal.industries.length === index + 1 && <input type="text" name="industry_other" className="oz-contact-us-modal-input__others" placeholder="" />}
                      </Col>
                    ))}
                  </Row>
                </Form.Group>
                <Form.Group controlId="formInterestedCheck">
                  <Form.Label className="pl-3">Interested in:</Form.Label>
                  <div className="row">
                    {modal.interestedIn.map((name, index) => (
                      <div className="col-md-6 col-lg-3" key={index}>
                        <label className="oz-contact-us-modal__body-checkbox">
                          <input
                            type="checkbox"
                            id={`interested-${index}`}
                            name={name}
                            onChange={this.interestedInCheckBoxChange}
                            checked={this.state.interestedInList[`interested-${index}`]} />
                          <span />
                          {name}
                        </label>
                      </div>
                    ))}
                  </div>
                </Form.Group>
                <Form.Group controlId="formLookingIn">
                  <Form.Label className="pl-3">Looking in:</Form.Label>
                  <div className="row">
                    {modal.lookingIn.map((name, index) => (
                      <div className="col-md-6 col-lg-3" key={index}>
                        <label className="oz-contact-us-modal__body-radio">
                          <input
                            type="radio"
                            id={`looking-${index}`}
                            value={`looking-${index}`}
                            onChange={this.lookingInCheckBoxChange}
                            checked={this.state.lookingIn === `looking-${index}`} />
                          <span />
                          {name}
                        </label>
                      </div>
                    ))}
                  </div>
                </Form.Group>
                <div className="row">
                  <div className="col mb-3">
                    <Form.Group controlId="formMessage">
                      <Form.Label>Message:</Form.Label>
                      <Form.Control name="message" as="textarea" rows="3" />
                    </Form.Group>
                  </div>
                  <a href="mailto:info@openznet.com" target="_blank">Contact us directly</a>
                </div>
                <div className="row">
                  <div className="col text-center">
                    <OzButton ux={modal.ux} data={modal.data.btnText} />
                  </div>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
        <OzConfirmModal confirm={confirm} show={this.state.isSucceed} onClick={this.toggleModal} />
      </div>
    )
  }
}

OzContactUsModal.propTypes = {
};

OzContactUsModal.defaultProps = {};

export default OzContactUsModal
