import React from 'react'
import './index.scss'

const OzNotification = () => (
  <div className="oz-notification">
    <div className="oz-notification__title">
      <span className="oz-notification__title-sub">
        Discover Benefits of
      </span>
      <img src="/assets/images/factor_logo.png" alt="" />
      <span className='oz-notification__title-for'> ELEMENT for</span>
    </div>
    <div className="oz-notification__text">
      <b>
        <div className="oz-notification__text-dynamic">
          <span>Advertising.</span>
          <span>Publishing.</span>
          <span>Tracing.</span>
          <span>Enhancing.</span>
          <span>Communication.</span>
          <span>Notifications.</span>
        </div>
      </b>
    </div>
  </div>
)

export default OzNotification
