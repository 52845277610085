import React from 'react'
import './index.scss'

const CobaltOzHero = ({ hero }) => (
  <section className="cobalt-oz-hero" style={{ backgroundImage: `url(${hero.img})` }}>
    <div className="cobalt-oz-hero__mask" style={{ backgroundColor: hero.mask }} />
    <div className="cobalt-oz-hero__content">
      {hero.top_comment ? <h6>{hero.top_comment}</h6> : ''}
      {hero.title ? <h1>{hero.title}</h1> : ''}
      {hero.sub_title ? <h2>{hero.sub_title}</h2> : ''}
      {hero.bottom_comment ? <h4>{hero.bottom_comment}</h4> : ''}

      {hero.quote ? (
        <div className="oz-hero__content__quote">
          {hero.quote}
          <h5>{hero.quote_author}</h5>
        </div>
      ) : ''}
      {hero.show_button === true && (
        <a
          href="mailto:sysadmin@openznet.com"
          target="_blank"
        >
          {hero.btn_label}
        </a>
      )}
    </div>
    {/*<div className="oz-hero__footer">*/}
    {/*  <h3>{hero.bottom_title}</h3>*/}
    {/*</div>*/}
  </section>
)

export default CobaltOzHero
