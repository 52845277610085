import React, { Component } from "react";
import { connect } from "react-redux";
import OzPageSolutionsSection from "./Section";
import * as rs from '../../utils/resource'
import FooterComp from '../../components/Footer'
import OzContactBar from '../../components/OzContactUsBar'
import OzHero from "../../components/OzHero";
import OzContactUsModal from "../../components/OzContactUsModal";
import OzRectMenu from '../../components/OzRectMenu'

class PageSolutions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
    }
  }

  componentDidMount() {}

  toggleContactUsModal = (val) => {
    this.setState({isModalVisible: !this.state.isModalVisible})
  }

  render() {
    const { sections } = rs.solutions_page
    const tempMenu = ["Notification Management", "ATSC 3.0 Management", "Application Management", "Content Management", "Website Management" ]
    return (
      <>
        {/* <OzFirstScreenSolutions /> */}
        <OzHero hero={rs.first_screens.solutions} callbackModal={this.toggleContactUsModal} />
        {/* <SolutionsMenu menus={rs.solutions_page.sections.map(section => section.title)}/> */}
        <OzRectMenu menus={tempMenu}/>
        {sections.map((section, index) => (
          <OzPageSolutionsSection id={section.title.split(' ').join('-').toLowerCase()} key={index} section={section}/>
        ))}
        <OzContactBar section={rs.contact_us_bar.solutions} callbackModal={this.toggleContactUsModal} />
        <OzContactUsModal modal={rs.contactUsModal} confirm={rs.confirmModal} callbackModal={this.toggleContactUsModal} isModalVisible={this.state.isModalVisible} />
        <FooterComp />
      </>
    )
  }
}

PageSolutions.propTypes = {
};

PageSolutions.defaultProps = {};

export default PageSolutions
