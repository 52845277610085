import React, { Component } from 'react'

import * as rs from '../../utils/resource'

import FooterComp from '../../components/Footer'
import OzTestimol from './Section/OzTestimol'

import CobaltOzHero from '../../components/CobaltOzHero'
import OzNotification from './Section/OzNotification'
import OzComment from './Section/OzComment'
import OzHomeProducts from './Section/OzHomeProducts'
import CobaltOzContactBar from '../../components/CobaltOzContactUsBar'


import './index.scss'

class PageCobalt extends Component {

  render() {
    return (
      <>
        <CobaltOzHero hero={rs.first_screens.cobalt} />
        <OzTestimol />
        {/* <OzComment /> */}
        <OzNotification />
        <OzHomeProducts homeProducts={rs.homeProducts} />
        <CobaltOzContactBar section={rs.contact_us_bar.home} />

        <FooterComp />
      </>
    )
  }
}

PageCobalt.propTypes = {}

PageCobalt.defaultProps = {}

export default PageCobalt
