import React, { Component } from "react";
import { connect } from "react-redux";
import './index.scss'

class OzCoverFlow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
    }
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { coverflow } = this.props

    return (
      <div className="oz-coverflow">
        <div className="oz-coverflow-list">
          {coverflow.map((product, index) => {
            return (
              <div className="oz-coverflow-box" key={index}>
                <div className="oz-coverflow-inner">
                  <img src={product.img} alt="" />
                  <div className="overlay">
                    <div className="overlay-img">
                      <img src={product.overlay} />
                    </div>
                    <strong>{product.title}</strong>
                  </div>
                  <a
                    className="oz-coverflow-inner__rollover"
                    href={`/products#${product.title.toLowerCase()}`}
                  >
                    <div className="oz-coverflow-inner__rollover__box">
                      <div className="oz-coverflow-inner__icon">
                        <img src={product.overlay} alt="" />
                      </div>
                      <strong>{product.title}</strong>
                      <span>{product.description}</span>
                    </div>
                  </a>
                </div>
              </div>
            )
          })}
        </div>
        {/*<div className="oz-coverflow__overlay">*/}
        {/*  {this.state.active != null ? <h3>{coverflow[this.state.active].description}</h3> : <h3>{defaultDesciption}</h3>}*/}
        {/*</div>*/}
      </div>

    )
  }
}

export default OzCoverFlow