import React, { Component } from "react";
import { connect } from "react-redux";

import './index.scss'

class OzRectMenu extends Component {

  render() {
    const { menus } = this.props
    return (
      <section className="oz-rect-menu">
        {menus.map( (product, index) => {
          return (
            <div
              className="menu-item"
              key={index}
            >
              <div>
                <a href={`#${product.split(' ').join('-').toLowerCase()}`}>{product}</a>
              </div>
              <div className="menu-item__bottom" />
            </div>
          )
        })}
      </section>
    )
  }
}

OzRectMenu.propTypes = {
};

OzRectMenu.defaultProps = {};

export default OzRectMenu
