import React, { Component } from "react";
import { connect } from "react-redux";
import SectionWhyChooseUsItems from './SectionWhyChooseUsItems'
import './index.scss'
class OzWhyChooseUs extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const { title, items } = this.props

    return (
      <div className="oz-why-choose-us">
        <div className="oz-section">
          <h3 className="oz-section__title">{title}</h3>
        </div>
        <SectionWhyChooseUsItems items={items} />
      </div>
    )
  }
}

OzWhyChooseUs.propTypes = {
};

OzWhyChooseUs.defaultProps = {
  className: ""
};

export default OzWhyChooseUs
