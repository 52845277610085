import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import OzServiceItem from "./Section";
import "./index.scss";
import { withRouter } from "../../../../utils/hook";

class OzServices extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const {
      title,
      btn_label,
      services,
      background,
      header_background
    } = this.props;

    // const services_first_row = services.slice(0, 3);
    // const services_second_row = services.slice(3, 5);

    return (
      <div className="oz-services">
        <div className="oz-services-pre">
          <div className="oz-content">
            <h3 className="oz-content__title" onClick={() => this.props.router.history("/services")}>Services</h3>
          </div>
        </div>
        <div className="oz-services-rows row">
          {services.map((item, index) => {
            return (
              <OzServiceItem
                key={index}
                title={item.title}
                description={item.description}
                front_img={item.img}
              />
            )
          })}
          {/*<div className="oz-services-rows__row oz-services-rows__row-lane">*/}
          {/*  {services_second_row.map((item, index) => {*/}
          {/*    return (*/}
          {/*      <OzServiceItem*/}
          {/*        key={index}*/}
          {/*        title={item.title}*/}
          {/*        description={item.description}*/}
          {/*        front_img={item.img}*/}
          {/*      />*/}
          {/*    )*/}
          {/*  })}*/}
          {/*</div>*/}
        </div>
      </div>
    )
  }
}

OzServices.propTypes = {};

OzServices.defaultProps = {
  className: ""
};

export default withRouter(OzServices)
