import React, { Component } from 'react'
import * as rs from '../../utils/resource'

import FooterComp from '../../components/Footer'
import OzContactBar from '../../components/OzContactUsBar'
import OzHero from '../../components/OzHero'
import OzTestimol from './Section/OzTestimol'
import OzContactUsModal from '../../components/OzContactUsModal'
import OzIntro from './Section/OzIntro'
import './index.scss'

class PageAboutUs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalVisible: false
    }
  }

  componentDidMount() {}

  toggleContactUsModal = val => {
    this.setState({ isModalVisible: !this.state.isModalVisible })
  }

  render() {
    const { sections } = rs.about_page
    return (
      <>
        <OzHero hero={rs.first_screens.aboutUs} />
        <OzTestimol />
        {/* <OzQuote title={quote.title} className="oz-quote-about"/> */}
        {/*{sections.map((sections, index) => {*/}
        {/*  return (<OzPageAboutUsSection key={index} section={sections} left_mode={index%2}/>)*/}
        {/*})}*/}
        {/* <OzWhyChooseUs {...rs.why_choose_us}/> */}
        <OzIntro sections={sections} />
        <OzContactBar section={rs.contact_us_bar.aboutUs} callbackModal={this.toggleContactUsModal} />
        <OzContactUsModal
          modal={rs.contactUsModal}
          confirm={rs.confirmModal}
          callbackModal={this.toggleContactUsModal}
          isModalVisible={this.state.isModalVisible}
        />
        <FooterComp />
        {/* <div className="oz-page-about-bottom">
          <Button>CONTACT US</Button>
        </div> */}
      </>
    )
  }
}

PageAboutUs.propTypes = {}

PageAboutUs.defaultProps = {}

export default PageAboutUs
