import React, {Component} from "react";
import './index.scss'

class SectionWhyChooseUsItems extends Component {

  render() {
    const {items} = this.props

    return (
      <section className="oz-section-why-choose-us">
        {items.map((item, index) => (
          <div className="oz-section-why-choose-us-item" key={ index }>
            <div className="oz-check-mark">
              <span>✓</span>
            </div>
            <div className="oz-content">
              <div className="oz-content__header">
                <img src={item.img} />
              </div>
              <h3 className="oz-content__title">{item.title}</h3>
              <h4 className="oz-content__description">{item.subtitle}</h4>
            </div>
          </div>
        ))}
      </section>
    )
  }
}

SectionWhyChooseUsItems.propTypes = {};

SectionWhyChooseUsItems.defaultProps = {};

export default SectionWhyChooseUsItems
