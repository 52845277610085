import React from 'react'
import './index.scss'

const OzNotification = () => (
  <div className="cobalt-oz-notification">
    <div className="cobalt-oz-notification__title">
      <span className="cobalt-oz-notification__title-sub">
        Discover Benefits of
      </span>
      <img src="/assets/images/Cobalt-Logo-black.png" alt="" />
      <span className='cobalt-oz-notification__title-for'> for</span>
    </div>
    <div className="cobalt-oz-notification__text">
      <b>
        <div className="cobalt-oz-notification__text-dynamic">
          <span>Cost-effective Advertising</span>
          <span>Informing your customers</span>
          <span>Engaging with your clients</span>
          <span>Entertaining your clients while they wait</span>
        </div>
      </b>
    </div>
  </div>
)

export default OzNotification
