import React, { Component } from "react";
import "./index.scss";

class OzPageProductsSectionLeft extends Component {
  render() {
    const { section, id } = this.props;

    const {
      title,
      text,
      background,
      icon,
      side_img,
      star_text,
      clip_path
    } = section;

    return (
      <>
        <div
          className="oz-page-products-section-left-id"
          id={id.toLowerCase()}
          ref={id.toLowerCase()}
        />
        <div className="oz-page-products-section-left">
          <div className="oz-page-products-section-left__side-img">
            <img src={side_img} style={{
              clipPath: clip_path,
              WebkitClipPath: clip_path,
            }} />
          </div>
          <div className="oz-page-products-section-left__content">
            <div
              className="oz-page-products-section__content__back"
              style={{
                maskImage: `url(${background})`,
                WebkitMaskImage: `url(${background})`
              }}
            >
              <div />
            </div>
            <div className="oz-page-products-section-left__content__banner">
              <div className="oz-page-products-section-left__content__banner__bg">
                {/* <img src={banner} /> */}
                <div className="oz-page-products-section-left__content__banner__bg__shape" />
                <span>{title}</span>
              </div>
              <div className="oz-page-products-section-left__content__banner__icon">
                <img src={icon} />
              </div>
              <div className="oz-page-products-section-left__content__banner__mask" />
            </div>
            <div className="oz-page-products-section-left__content__body">
              <h3>{text}</h3>
            </div>
            <div className="oz-page-products-section-left__content__bottom">
              <span className="oz-page-products-section-left__content__bottom-star">
                <img src="/assets/images/Products/Star.png" />
                {star_text}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

OzPageProductsSectionLeft.propTypes = {};

OzPageProductsSectionLeft.defaultProps = {};

export default OzPageProductsSectionLeft;
