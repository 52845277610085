import React, { Component } from "react";
import "./index.scss";
class OzPageOcircleSection extends Component {
  render() {
    const { section, id } = this.props;

    const {
      title,
      sub_title,
      front_img,
      back_img,
      light_theme,
      left_img,
      side_img,
      clip_path,
    } = section;

    return (
      <>
        <div className="oz-page-ocircle-section-id" id={id} />
        <div className="oz-page-ocircle-section">
          <div className="oz-page-ocircle-section__content">
            <div className="oz-page-ocircle-section__content__banner">
              <div className="oz-page-ocircle-section__content__banner__bg">
                <img
                  className="oz-page-ocircle-section-left__content__title__img"
                  src={left_img}
                />
                <div className="oz-check-mark">
                  <span>✓</span>
                </div>
                <span>{title}</span>
              </div>
            </div>
            <div className="oz-page-ocircle-section__content__body">
              <h3>{sub_title}</h3>
            </div>
          </div>
          {side_img && (
            <div className="oz-page-ocircle-section__side-img">
              <img src={side_img} style={{
                clipPath: clip_path,
                WebkitClipPath: clip_path,
              }} />
            </div>
          )}
        </div>
      </>
    );
  }
}

OzPageOcircleSection.propTypes = {};

OzPageOcircleSection.defaultProps = {};

export default OzPageOcircleSection;
