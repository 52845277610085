import {
  GRAPHQL_API,
  SAVE_PAGE_INFO,
  CHANGE_PAGE_LINK,
  RESET_STORE,
  UI,
  UPDATE_ROUTE_INFO
} from "../actions/types";

const initialState = {
  menus_data: [],
  page_info: {},
  page_link: "",
  sources: {},
  is_loading: false,
  origin_pages_data: [],
  collectionsInfo: [],
  domainsInfo: {},
  curDomainInfo: {},
  showLeftSidebar: false,
  leftBarInfo: {
    navSubMenus: [],
    arrNum: [],
    arrSubNum: [],
    menuNum: 0,
    pageNum: 0,
  },
  route_info: {
    displayType: -1,
    numMenu: -1,
    numPage: -1,
    collectionName: null,
    numDetail: -1
  }
};
function menusReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ROUTE_INFO: {
      return Object.assign({}, state, {
        route_info: { ...action.data },
      });
    }
    case UI.UPDATE_LEFT_SIDEBAR_INFO: {
      return Object.assign({}, state, {
        leftBarInfo: { ...action.data },
      });
    }
    case UI.TOGGLE_LEFT_SIDEBAR: {
      console.log("on Reducer");
      if (state.showLeftSidebar == false)
        return Object.assign({}, state, {
          showLeftSidebar: true
        });
      return Object.assign({}, state, {
        showLeftSidebar: false
      });
    }
    case GRAPHQL_API.DOMAINS_GET_REQUEST: {
      return {
        ...state,
        is_loading: true,
        statusMsg: "Please wait... Loading Domains data from DB"
      };
    }
    case GRAPHQL_API.DOMAINS_GET_SUCCESS: {
      // console.log("---------- domainsData", action.data);
      if (action.data && action.data[0]) {
        let header_info = {}
        if (action.data[0].list != undefined) {
          header_info = action.data[0].list.filter(
            item => item.domain_name == 'npgproposal.oelement.openznet.com',
          )[0]
        }
        return Object.assign({}, state, {
          domainsInfo: { ...action.data[0] },
          curDomainInfo: { ...header_info },
          is_loading: false,
          statusMsg: "Success to load Domains Data from DB"
        });
      }
      return { ...state };
    }
    case GRAPHQL_API.DOMAINS_GET_FAILURE: {
      return {
        ...state,
        is_loading: false,
        statusMsg: "Failure to load Domains Data from DB"
      };
    }
    case GRAPHQL_API.PAGES_GET_REQUEST: {
      return {
        ...state,
        is_loading: true,
        statusMsg: "Please wait... Loading PAGES data from DB"
      };
    }
    case GRAPHQL_API.PAGES_GET_SUCCESS: {
      // console.log("----------Pfages_get-success", action.data);
      /*if (state.page_info.id) {
        // console.log("uhahhahahhahah", state.page_info.id);
        let changedData = action.data.filter(
          item => item.id == state.page_info.id
        );
        return Object.assign({}, state, {
          origin_pages_data: [...action.data],
          page_info: changedData[0],
          is_loading: false,
          statusMsg: "Success to load PAGES Data from DB"
        });
      }*/
      return Object.assign({}, state, {
        origin_pages_data: [...action.data],
        is_loading: false,
        statusMsg: "Success to load PAGES Data from DB"
      });
    }
    case GRAPHQL_API.PAGES_GET_FAILURE: {
      return {
        ...state,
        is_loading: false,
        statusMsg: "Failure to load PAGES Data from DB"
      };
    }
    case GRAPHQL_API.COLLECTIONS_GET_REQUEST: {
      return {
        ...state,
        is_loading: true,
        statusMsg: "Please wait... Loading Collections data from DB"
      };
    }
    case GRAPHQL_API.COLLECTIONS_GET_SUCCESS: {
      // console.log("----------", action.data);
      return Object.assign({}, state, {
        collectionsInfo: [...action.data],
        is_loading: false,
        statusMsg: "Success to load Collections Data from DB"
      });
    }
    case GRAPHQL_API.COLLECTIONS_GET_FAILURE: {
      return {
        ...state,
        is_loading: false,
        statusMsg: "Failure to load Collections Data from DB"
      };
    }
    case GRAPHQL_API.PAGES_SAVE_REQUEST: {
      return {
        ...state,
        is_loading: true,
        statusMsg: "Please wait... Saving Pages data to DB"
      };
    }
    case GRAPHQL_API.PAGES_SAVE_SUCCESS: {
      return Object.assign({}, state, {
        current_pages_data: [...action.data],
        origin_pages_data: [...action.data],
        is_loading: false,
        statusMsg: "Success to save Pages Data from DB"
      });
    }
    case GRAPHQL_API.PAGES_SAVE_FAILURE: {
      return {
        ...state,
        is_loading: false,
        statusMsg: "Failure to save Pages Data from DB"
      };
    }
    case GRAPHQL_API.SOURCES_GET_REQUEST: {
      return {
        ...state,
        sources: {},
        is_loading: true,
        statusMsg: "Please wait... Loading Sources from DB"
      };
    }
    case GRAPHQL_API.SOURCES_GET_SUCCESS: {
      console.log('^^^^^^^^^^^^^^', action.data)
      return Object.assign({}, state, {
        sources: { ...action.data },
        is_loading: false,
        statusMsg: "Success to load Sources Data from DB"
      });
    }
    case GRAPHQL_API.SOURCES_GET_FAILURE: {
      return {
        ...state,
        is_loading: false,
        statusMsg: "Failure to load Sources Data from DB"
      };
    }
    case GRAPHQL_API.MENUS_GET_REQUEST: {
      return { ...state, sources: {}, is_loading: true };
    }
    case GRAPHQL_API.MENUS_GET_SUCCESS: {
      console.log("get menus success", action.data);
      return Object.assign({}, state, {
        menus_data: [...action.data],
        is_loading: false
      });
    }
    case GRAPHQL_API.MENUS_GET_FAILURE: {
      return { ...state, is_loading: false };
    }
    case SAVE_PAGE_INFO: {
      return Object.assign({}, state, {
        page_info: action.data
      });
    }
    case CHANGE_PAGE_LINK: {
      return Object.assign({}, state, {
        page_link: action.data
      });
    }
    case RESET_STORE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export default menusReducer;
