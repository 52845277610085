import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOM from 'react-dom';

import './menu.scss'

class ProductMenu extends Component {
  scrollTo = (id) => {
    const item = ReactDOM.findDOMNode(this.refs[id]);
    window.scrollTo(item.offsetTop);
  }

  render() {
    const { menus } = this.props

    return (
      <section className="oz-product-menu">
        {menus.map((product, index) => (
          <div className="menu-item" key={index}>
            <a
              href={`#${product.toLowerCase()}`}
              // onClick={() => this.scrollTo(`#${product.toLowerCase()}`)}
            >
              {product}
              <div className="menu-item__bottom" />
            </a>
          </div>
        ))}
      </section>
    )
  }
}

ProductMenu.propTypes = {
};

ProductMenu.defaultProps = {};

export default ProductMenu
