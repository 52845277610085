import React, { Component } from "react";
import { connect } from "react-redux";
import './index.scss'
class OzSolutions extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const {title, sub_title, className} = this.props
    return (<div className={"oz-solutions " + className}>
      <h3 className="oz-section__title">{title}</h3>
      <p className="oz-section__subtitle">{sub_title}</p>
    </div>)
  }
}

OzSolutions.propTypes = {
};

OzSolutions.defaultProps = {
  className: ""
};

export default OzSolutions
