import React, { Component } from "react";
import "./index.scss";

class OzPageProductsSection extends Component {
  render() {
    const { section, id } = this.props;

    const {
      title,
      text,
      background,
      icon,
      side_img,
      clip_path,
      star_text
    } = section;

    return (
      <>
        <div className="oz-page-products-section-id" id={id.toLowerCase()} ref={id.toLowerCase()} />
        <div className="oz-page-products-section">
          <div className="oz-page-products-section__content">
            <div
              className="oz-page-products-section__content__back"
              style={{
                maskImage: `url(${background})`,
                WebkitMaskImage: `url(${background})`
              }}
            >
              <div />
            </div>
            <div className="oz-page-products-section__content__banner">
              <div className="oz-page-products-section__content__banner__icon">
                <img src={icon} />
              </div>
              <div className="oz-page-products-section__content__banner__mask" />
              <div className="oz-page-products-section__content__banner__bg">
                {/* <img src={banner} /> */}
                <div className="oz-page-products-section__content__banner__bg__shape" />
                <span>{title}</span>
              </div>
            </div>
            <div className="oz-page-products-section__content__body">
              <h3>{text}</h3>
            </div>
            <div className="oz-page-products-section__content__bottom">
              <span className="oz-page-products-section__content__bottom-star">
                <img src="/assets/images/Products/Star.png" /> {star_text}
              </span>
            </div>
          </div>
          <div className="oz-page-products-section__side-img">
            <img src={side_img} style={{
              clipPath: clip_path,
              WebkitClipPath: clip_path,
            }} />
          </div>
        </div>
      </>
    );
  }
}

OzPageProductsSection.propTypes = {};

OzPageProductsSection.defaultProps = {};

export default OzPageProductsSection;
