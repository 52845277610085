export const products = [
  "Editor",
  "Creator",
  "Announcer",
  "Compositor",
  "Collector",
  "Streamer",
  "Organizer",
  "Administrator"
];

export const contact_us_bar = {
  home: {
    background: "assets/images/contact_us_bar_new.png",
    factor_logo: "/assets/images/factor_logo.png",
  },
  products: {
    background: "assets/images/contact_us_bar_new.png",
    factor_logo: "/assets/images/factor_logo.png",
  },
  solutions: {
    background: "assets/images/contact_us_bar_new.png",
    factor_logo: "/assets/images/factor_logo.png",
  },
  services: {
    background: "assets/images/contact_us_bar_new.png",
    factor_logo: "/assets/images/factor_logo.png",
  },
  ocircle: {
    background: "assets/images/contact_us_bar_new.png",
    factor_logo: "/assets/images/factor_logo.png",
  },
  aboutUs: {
    background: "assets/images/contact_us_bar_new.png",
    factor_logo: "/assets/images/factor_logo.png",
  },
};

export const contactUsModal = {
  industries: [
    "Content Provider",
    "Broadcasters",
    "Local governments",
    "Network Provider",
    "Emergency Managers",
    "Partners",
    "Cable/MSP",
    "Community Program",
    "Other:"
  ],
  interestedIn: ["Products", "Solutions", "Services", "ATSC 3.0", "Not sure"],
  lookingIn: [
    "0-3 months",
    "3-6 months",
    "6-12 months",
    "1-5 years",
    "Not sure"
  ],
  data: {
    title: 'Contact Us Form',
    subtitle: '',
    btnText: 'SUBMIT',
  },
  ux: {
    backgroundColor: '#AA6E56',
    color: '#FFFFFF',
    hoverColor: '#FFFFFF',
    fontSize: '18',
  },
};

export const confirmModal = {
  data: {
    title: 'Your form submission has been successful.',
    subtitle: 'Our dedicated team will respond to your request shortly.',
    btnText: 'OK',
    icon: '/assets/images/confirm_icon.png',
  },
  ux: {
    width: '230',
    height: '100',
    backgroundColor: '#E6E5E5',
    border: '2px solid #65605F',
  },
  button: {
    ux: {
      backgroundColor: '#65605F',
      color: '#000000',
      hoverColor: '#FFFFFF',
      fontSize: '18',
    },
  },
}

export const menus = [
  { label: "Home", path: "", val: "" },
  { label: "Products", path: "products", val: "products" },
  { label: "Solutions", path: "solutions", val: "solutions" },
  { label: "Services", path: "services", val: "services" },
  { label: "Cobalt", path: "cobalt", val: "cobalt" },
  { label: "O-Circle", path: "ocircle", val: "ocircle" },
  { label: "Blogs", path: "blog", val: "blog" },
  { label: "About Us", path: "about_us", val: "about_us" }
];

export const home_carousel = [
  {
    title: "The straightest path is often the best path",
    sub_title:
      "See how our design delivers content straight from creation to presentation",
    sub_title_style: {
      margin_bottom: "10px"
    },
    show_button: true,
    btn_label: "Learn about our design",
    imgURL: "assets/images/Home/Carousel/picture1.png",
    mask: "rgba(0, 0, 0, 0.5)"
  },
  {
    title: "We make it our business to solve challenges facing your world",
    sub_title:
      "Learn how our products can save time and money to tame your complex environment",
    sub_title_style: {
      margin_bottom: "10px"
      // width: '60vw'
      // margin: '0px calc(50% - 30vw)',
      // text_align: 'left',
      // padding:'0px !important'
    },
    show_button: true,
    btn_label: "Schedule a demo",
    imgURL: "assets/images/Home/Carousel/picture2.png",
    mask: "rgba(0, 0, 0, 0.6)"
  },
  {
    title: "Disrupt the status-quo to build a better system",
    sub_title: "Our open-architecture solutions separate us from the rest",
    sub_title_style: {
      margin_bottom: "10px"
    },
    show_button: true,
    btn_label: "Learn how",
    imgURL: "assets/images/Home/Carousel/picture3.png",
    mask: "rgba(0, 0, 0, 0.5)"
  },
  {
    title: "Tap into your customer’s impulse and increase your revenue",
    sub_title: "Watch the ripple effect of the content propagation",
    sub_title_style: {
      margin_bottom: "10px"
      // width: '60vw'
      // margin: '0px calc(50% - 30vw)',
      // text_align: 'left',
      // padding:'0px !important'
    },
    show_button: true,
    btn_label: "Schedule a demo",
    imgURL: "assets/images/Home/Carousel/picture4.png",
    mask: "rgba(0, 0, 0, 0.5)"
  },
  {
    title: "We are with you from inception to production",
    sub_title: "Ask us how our services can help",
    sub_title_style: {
      margin_bottom: "75px"
    },
    show_button: true,
    btn_label: "Ask us how",
    imgURL: "assets/images/Home/Carousel/picture5.png",
    mask: "rgba(73, 73, 73, 0.5)"
  }
];

export const solutions_menus = [
  {
    title: "News Organizations",
    sub_title:
      "Poviding backstories, with multimedia enhanced layer provide a better user expeirence for your viewers and increase ad revenu opportunities, while  increasing viewership.",
    imgURL: "assets/images/Solutions/PictureNoteifications.png"
  },
  {
    title: "Small Business Owners",
    sub_title:
      "is a network of displays that that boosts local presence, strengthens community ties, and engages customers for small business success.",
    imgURL: "assets/images/Solutions/ATSC3.0.png"
  },
  {
    title: "Emergency \n" +
      "Managers\n" +
      "-\n" +
      "Disaster \n" +
      "Recovery ",
    sub_title: "Crisis mannagement is not an easy task in high stressfull situations.  Having the right notification and tracing tools can ease  day-to-day operations and workflow and help the public.",
    imgURL: "assets/images/Solutions/AppMgmt-Middle.png"
  },
  {
    title: "Content Creators\n" +
      "- \n" +
      "Content Owners\n" +
      "-\n" +
      "Content Publishers",
    sub_title:
      "Easily manage, organize, highlight, share and publish your content onto any platform, while showcasing them in the best light possible, by providing an enhanced layer. ",
    imgURL: "assets/images/Solutions/section_1_frame.png"
  },
  {
    title: "Broadcasters",
    sub_title:
      "Synchronize your broadcast and digital to optimize your work flow and increase revenue by providing interactive ads. Prepare your system for next generation TV delivery.",
    imgURL: "assets/images/Solutions/WebMgmt.png"
  },
];

export const first_screens = {
  home: {
    img: "assets/images/Home/firstscreen_bg.png",
    title: "Disrupt the status-quo to build a better system",
    sub_title: "Our open-architecture design separates us from the rest.",
    show_button: true,
    btn_label: "Learn how",
    bottom_title: "Our Mission Statement:",
    mask: "rgba(0, 0, 0, 0.5)"
  },
  cobalt: {
    img: "assets/images/Home/firstscreen_bg.png",
    top_comment: "WE SERVE LOCAL BUSINESSES AND COMMUNITIES",
    title: "Your Screen. Your Way.",
    sub_title: `Elevate your brand with our connected network of displays, tailor-made for small businesses, offering the prowess of a corporate giant effortlessly and cost effectively.`,
    show_button: true,
    bottom_comment: "Learn how we can help make your products and services more noticeable in your local communities.",
    btn_label: "CONTACT US FOR FREE TRIAL",
    bottom_title: "Our Mission Statement:",
    mask: "rgba(0, 0, 0, 0.5)"
  },
  blogs: {
    img: "assets/images/Solutions/firstscreen_bg.png",
    title: "Blog",
    sub_title:
      "Adopting an open-architecture design enables creativity, reduces cost and puts you in the driver seat.",
    show_button: false,
    btn_label: "Schedule a demo",
    bottom_title: "Learn about our systems:",
    mask: "rgba(0, 0, 0, 0.5)"
  },
  solutions: {
    img: "assets/images/Solutions/firstscreen_bg.png",
    title: "The straightest path is often the best path",
    sub_title:
      "Adopting an open-architecture design enables creativity, reduces cost and puts you in the driver seat.",
    show_button: true,
    btn_label: "Schedule a demo",
    bottom_title: "Learn about our systems:",
    mask: "rgba(0, 0, 0, 0.5)"
  },
  services: {
    img: "assets/images/Services/firstscreen_bg_new.png",
    title: "We make it our business to solve challenges facing your world",
    sub_title: "No job is too small or too big for us.",
    show_button: true,
    btn_label: "Talk to an expert",
    bottom_title: "Learn about our services:",
    mask: "rgba(0, 0, 0, 0.3)"
  },
  ocircle: {
    img: "assets/images/O-Circle/firstscreen_bg.jpg",
    sub_title: "Empower Your Business.  Strengthen Your Community.",
    show_button: true,
    btn_label: "Join Us Today",
    description: "Welcome to our platform dedicated to creating a circle of local business communities. We believe in the power of connection and collaboration, where local businesses thrive, and communities flourish. Our network of displays is at the heart of this mission, providing a dynamic advertising platform that not only benefits businesses but also strengthens the bonds within each community.",
    mask: "rgba(0, 0, 0, 0.6)"
  },
  products: {
    img: "assets/images/Products/firstscreen_bg.png",
    logo: "assets/images/Products/OElement_new.png",
    sub_title:
      "Learn how you can save time and money by taming your complex environment.",
    show_button: true,
    btn_label: "Get the datasheet",
    bottom_title: "Read about our products:",
    mask: "rgba(0, 0, 0, 0.6)"
  },
  aboutUs: {
    img: "/assets/images/AboutUs/aboutUs-header-image.jpg",
    quote:
      "“Nothing great in the world has ever been accomplished without passion.”",
    quote_author: "- Georg Hegel",
    show_button: false,
    bottom_title: "Learn about our passion:",
    mask: "rgba(0, 0, 0, 0.7)"
  }
};

export const services_page = {
  quote: {
    title:
      "We believe in challenging existing business models by pursuing newer and more effective ways to solve today&#39;s problems. Our online management system modernizes the way you organize and retrieve information, making the process fast, simple, and cost effective."
  },
  sections: [
    {
      title: "Technical Consultancy",
      sub_title:
        "The OpenZNet consulting team has over 20 years of architecture, design and implementation experience. We can help with various phases of software developement, from brainstorming and requirements gathering to design, software architecture, and documentation.  The team has been involved in various standard bodies and organizations and is intimiately familiar with all aspects of ATSC 3.0 interactive application and delivery. Our team can help make the transition to ATSC 3.0 smooth.",
      front_img: "/assets/images/Services/section3_front.png",
      back_img: "/assets/images/Services/section3_back.png",
      left_img: "/assets/images/Services/technical_consulting.png",
      side_img: "/assets/images/Services/consulting.png",
      clip_path: "polygon(35% 0, 100% 0, 100% 100%, 0% 100%)",
      type: "right"
    },

    {
      title: "Software Development",
      sub_title:
        "OpenZNet’s talented software team is here to assist you with your software development needs. Our team has experience in cloud computing, website building, mobile app development, embedded devlopment, UX Design, as well as develpmening using popular UI frameworks such as React.JS and Angular.JS. We can help from inception to delivery and take on complete software development project. We can also provide staff augmentation for your software development and testing needs.",
      front_img: "/assets/images/Services/section2_front.png",
      back_img: "/assets/images/Services/section2_back.png",
      left_img: "/assets/images/Services/software_development.png",
      side_img: "/assets/images/Services/software_front.jpg",
      clip_path: "polygon(0 0, 65% 0, 100% 100%, 0% 100%)",
      type: "left"
    },

    {
      title: "Program Management",
      sub_title:
        "Successful Programs are critical to your business so we created a team to help you succeed. Whether you need someone to take over a complete program or manage a small piece, our team is here to help. Our team has experience managing multi-vendor programs. Leverage our specialized technology expertise as you bring new technology into your business.",
      front_img: "/assets/images/Services/Serv5.png",
      back_img: "/assets/images/Services/section5_back.png",
      left_img: "/assets/images/Services/program_management.png",
      side_img: "/assets/images/Services/program_front.jpg",
      clip_path: "polygon(35% 0, 100% 0, 100% 100%, 0% 100%)",
      type: "right"
    },

    {
      title: "UX Design",
      sub_title:
        "Proper UX design can make a product successful. Our UX design allows your consumers to intuitively use a product. Our UX experts have experience in designing for a 10-foot experience with a TV remote. Our experts can help you design killer user interfaces whether it be for a website, mobile app, or TV experience. Our products reach all these devices and we are experts with the intricacies of maintaining a consistent UX across all these platforms.",
      front_img: "/assets/images/Services/Serv4.png",
      back_img: "/assets/images/Services/section4_back.png",
      left_img: "/assets/images/Services/ux_design.png",
      side_img: "/assets/images/Services/ux_front.jpg",
      clip_path: "polygon(0 0, 65% 0, 100% 100%, 0% 100%)",
      type: "left"
    },
    {
      title: "Integration",
      sub_title:
        "OpenZNet’s integration team is here to help you complete your project throughout the integration process. Offload complete integration projects or bring us in for assistance with specific pieces. Our team is here to help you whatever the size of the project. Stay involved in as much or as little as you like. You can hand the project off and rely on us to bring it to completion, or bring us in as part of your team for the elements that are painful or difficult to deal with. We can focus on external partners and vendors, allowing you to focus your team on the items that are most important to you and your business.",
      front_img: "/assets/images/Services/section1_front.png",
      back_img: "/assets/images/Services/section1_back.png",
      left_img: "/assets/images/Services/integration.png",
      side_img: "/assets/images/Services/integrations.png",
      clip_path: "polygon(35% 0, 100% 0, 100% 100%, 0% 100%)",
      type: "right"
    }
  ]
};

export const ocircle_page = {
  quote: {
    title:
      "Ready to take your business and community involvement to the next level?",
    sub_title: "Become a member today and experience the benefits of being a part of our thriving local business circle. Your success is our success, and we're excited to welcome you into our community!"
  },
  sections: [
    {
      title: "Amplify Your Local Presence",
      sub_title:
        "When you join our O-Circle community, your business gains increased visibility within your local area. Showcase your products and services to a captive local audience through our advertising network.",
      // front_img: "/assets/images/Services/section3_front.png",
      // back_img: "/assets/images/Services/section3_back.png",
      // left_img: "/assets/images/Services/technical_consulting.png",
      side_img: "/assets/images/O-Circle/first_item.png",
      clip_path: "polygon(35% 0px, 100% 0px, 100% 100%, 0% 100%)",
      type: "right"
    },

    {
      title: "Foster Lasting Connections",
      sub_title:
        "We're not just a network; we're a close-knit community of local business owners. Connect with like-minded entrepreneurs, share ideas, and form valuable partnerships that can take your business to new heights.",
      // front_img: "/assets/images/Services/section2_front.png",
      // back_img: "/assets/images/Services/section2_back.png",
      // left_img: "/assets/images/Services/software_development.png",
      side_img: "/assets/images/O-Circle/second_item.png",
      clip_path: "polygon(0 0, 65% 0, 100% 100%, 0% 100%)",
      type: "left"
    },

    {
      title: "Drive Customer Engagement",
      sub_title:
        "Our community is all about engaging with your customers on a deeper level. You'll find innovative ways to captivate and satisfy your audience, turning them into loyal supporters of your business.",
      // front_img: "/assets/images/Services/Serv5.png",
      // back_img: "/assets/images/Services/section5_back.png",
      // left_img: "/assets/images/Services/program_management.png",
      side_img: "/assets/images/O-Circle/third_item.png",
      clip_path: "polygon(35% 0, 100% 0, 100% 100%, 0% 100%)",
      type: "right"
    },

    {
      title: "Cost-Efficient Advertising",
      sub_title:
        "Advertising within our community is not only effective but also budget-friendly. Save on marketing costs while reaching a highly targeted local audience.",
      // front_img: "/assets/images/Services/Serv4.png",
      // back_img: "/assets/images/Services/section4_back.png",
      // left_img: "/assets/images/Services/ux_design.png",
      side_img: "/assets/images/O-Circle/fourth_item.png",
      clip_path: "polygon(0 0, 65% 0, 100% 100%, 0% 100%)",
      type: "left"
    },
    {
      title: "Resourceful Support System",
      sub_title:
        "We provide a range of resources, from expert advice to workshops and events, designed to help your business thrive. Tap into our collective knowledge and experience to overcome challenges and seize opportunities.",
      // front_img: "/assets/images/Services/section1_front.png",
      // back_img: "/assets/images/Services/section1_back.png",
      // left_img: "/assets/images/Services/integration.png",
      side_img: "/assets/images/O-Circle/fifth_item.png",
      clip_path: "polygon(35% 0, 100% 0, 100% 100%, 0% 100%)",
      type: "right"
    },
    {
      title: "Community Empowerment",
      sub_title:
        "By being a part of our community, you're contributing to the growth and prosperity of your local area. Strengthen the bonds within your community and play a role in its development.",
      // front_img: "/assets/images/Services/section1_front.png",
      // back_img: "/assets/images/Services/section1_back.png",
      // left_img: "/assets/images/Services/integration.png",
      side_img: "/assets/images/O-Circle/sixth_item.png",
      clip_path: "polygon(0 0, 65% 0, 100% 100%, 0% 100%)",
      type: "left"
    }
  ]
};

export const products_page = {
  sections: [
    {
      type: "right",
      title: "Cobalt",
      sub_title: "Boost Your Small Business with Our Networked Displays",
      text:
        "Take your small business to the next level with our cutting-edge networked displays. It not only improves your local presence but also strengthens your ties to the community. Plus, it creates eye-catching displays that keep your customers engaged and satisfied, helping your business thrive in your local market.",
      background: "/assets/images/Products/1-2-3-tracer-icon.png",
      icon: "/assets/images/Products/section-icon.png",
      side_img: "/assets/images/Products/sample.png",
      star_text: "Work-flow optimization",
      clip_path: "polygon(35% 0, 100% 0, 100% 100%, 0% 100%)"
    },
    {
      type: "right",
      title: "Organizer",
      sub_title: "Organizes a wide range of information in an easy-to-use folder structure such as applications, media assets, articles, and notifications.",
      text:
        "O-Element Organizer is a common platform to organize diverse sets of assets, reducing operational cost and increasing work-flow efficiency. The tool provides an easy-to-use folder style navigation allowing you to organize your assets effectively. By simplifying the interface, the tool dramatically reduces operational cost and increases workflow efficiency by sharing elements among working groups with just a single click. The system's foundation and overall design promotes workflow optimization.",
      background: "/assets/images/Products/section-1-icon.png",
      icon: "/assets/images/Products/section-icon.png",
      side_img: "/assets/images/Products/sample.png",
      star_text: "Work-flow optimization",
      clip_path: "polygon(35% 0, 100% 0, 100% 100%, 0% 100%)"
    },
    {
      type: "left",
      title: "Editor",
      sub_title: "Rich editing tools to edit articles, media metadata assets, notifications, and applications.",
      text:
        "In organizations where creating, managing, sharing and publishing content is critical to day-to-day business operations, O-Element Editor provides a set of easy-to-use media editing tools that equip your team with the ability to manage stories, images, videos, and widgets. The widgets provide a set of enhanced user experience features to connect your content with various social media platforms and other applications. In addition, it can connect with featues such as supplying directions, coupons, and reward programs. The tool offers the ability to view and modify the HTML and Javascript code.",
      background: "/assets/images/Products/section-2-icon.png",
      icon: "/assets/images/Products/section-icon.png",
      side_img: "/assets/images/Products/sample.png",
      star_text: "Value-added services",
      clip_path: "polygon(0 0, 65% 0, 100% 100%, 0% 100%)"
    },
    {
      type: "right",
      title: "Collector",
      sub_title: "Accurately collects the important and relevant information from applications and websites and report the information to your analytic systems.",
      text:
        "Adopting an improved data collection and feedback loop better suited for today's advancing environments enables content providers to collect relevant information, gather accurate audience measurements, and make adjustments to promote higher customer satisfaction. The O-Element Collector offers information that is essential in understanding the users' behavior. It increases revenue by creating a predictive model to change the layout and content viewed by users. The system can easily be integrated with other analytics systems.",
      background: "/assets/images/Products/section-3-icon.png",
      icon: "/assets/images/Products/section-icon.png",
      side_img: "/assets/images/Products/sample.png",
      star_text: "Revenue generation",
      clip_path: "polygon(35% 0, 100% 0, 100% 100%, 0% 100%)"
    },
    {
      type: "left",
      title: "Announcer",
      sub_title: "Ingests information and sends notifications/alerts to targeted sets of users based on business criteria such as: geolocation, target platform or user profile to bring value-added and revenue generating services.",
      text:
        "Up-to-date alerts and notifications that quickly deliver valuable information to your viewers keeps them loyal. The O-Element Announcer aides your team to quickly build notifications and deliver them. The O-Element Announcer can be easily integrated with other alert management systems to import notifications and then prepare for delivery onto broadcast as well as broadband. The tool additionally provides the ability to publish notifications to social media and other platforms at any scheduled time you choose.",
      background: "/assets/images/Products/section-4-icon.png",
      icon: "/assets/images/Products/section-icon.png",
      side_img: "/assets/images/Products/sample.png",
      star_text: "Customer retention",
      clip_path: "polygon(0 0, 65% 0, 100% 100%, 0% 100%)"
    },
    {
      type: "right",
      title: "Creator",
      sub_title: "Drag-and-drop tool with templates to build applications or dynamically configure website layouts instantly.",
      text:
        "Building applications on-the-fly enables content providers and advertisers to sieze revenue-generating opportunities quickly. The O-Element Creator is a novelty in a world of complex systems, where the user can quickly and easily drag and drop widgets to build specialized applications. The tool provides the ability to create and publish content-specific applications in less than a minute and make it available immediately or on a schedule for future delivery. The O-Element Creator provides the ability to change the layout of a website to meet the needs of today's dynamic news environment.",
      background: "/assets/images/Products/section-5-icon.png",
      icon: "/assets/images/Products/section-icon.png",
      side_img: "/assets/images/Products/sample.png",
      star_text: "New revenue opportunities",
      clip_path: "polygon(35% 0, 100% 0, 100% 100%, 0% 100%)"
    },
    {
      type: "left",
      title: "Administrator",
      sub_title: "Tools to configure websites, applications and manage users and subscribers to website content.",
      text:
        "Having control over your user management tools minimizes new employee start-up time. O-Element Administrator provides the ability for customers to manage their users quickly, letting them start being productive immediately. The tool manages user authentication and permission-based access to functions, as well as personal space to draft their content. In addition to managing the users of the system, our customers can use O-Element Administrator to manage their subscribers and users of their websites and applications. Our modular system can also work with your CRM system, if one is already in place.",
      background: "/assets/images/Products/section-6-icon.png",
      icon: "/assets/images/Products/section-icon.png",
      side_img: "/assets/images/Products/sample.png",
      star_text: "Reduce operational cost",
      clip_path: "polygon(0 0, 65% 0, 100% 100%, 0% 100%)"
    },
    {
      type: "right",
      title: "Compositor",
      sub_title: "Provides the tools to schedule and deliver applications over broadcast, as well as broadband to the companion devices.",
      text:
        "As systems are becoming larger and more complex, having a tool that enables the deployment of an application quickly and efficiently becomes crucial to the success of your application deployment strategy. The O-Element Compositor tool allows for access to various platforms, while minimizing the need for detailed knowledge of delivery systems. The tool simplifies delivery system complexities while enabling users to easily schedule content and create business logic for when and how an application is deployed and presented to end-users.",
      background: "/assets/images/Products/section-7-icon.png",
      icon: "/assets/images/Products/section-icon.png",
      side_img: "/assets/images/Products/sample.png",
      star_text: "Minimize external dependencies",
      clip_path: "polygon(35% 0, 100% 0, 100% 100%, 0% 100%)"
    },
    {
      type: "left",
      title: "Personalizer",
      sub_title: "User configuration for replacement of content based on a user preferences and can interface with an external ad decision engine.",
      text:
        "Targeted and personalized content is the holy grail of marketing, enhancing user experience while providing additional revenue opportunities. O-Element Personalizer enables your team to build business logic for content replacement and personalization. The personalization logic is based on users' demographics, such as age, sex, race, as well as other preferences, including hobbies, political views, and special interests. Additionally, the O-Element Personalizer provides the capability of creating geo-targets and zones for delivering content. The system itself can easily be integrated with an external ad decision engine.",
      background: "/assets/images/Products/section-8-icon.png",
      icon: "/assets/images/Products/section-icon.png",
      side_img: "/assets/images/Products/sample.png",
      star_text: "Revenue generation",
      clip_path: "polygon(0 0, 65% 0, 100% 100%, 0% 100%)"
    }
  ]
};

export const solutions_page = {
  sections: [
    {
      title: "Notification Management",
      description:
        "O-Element Notification solution provides a notification mechanism to streamline newsroom operation and delivery in under a second enabling a slew of value-added services for the end-users.  The solution is integrated with external notification systems such including government agencies to provide life-saving information, while enabling customization and co-branding.   It is also used for promotional notification, connecting your broadcast and broadband to drive viewers from one platform to another and keep them engaged.",
      background: "/assets/images/Solutions/Connect.png",
      center_img: "/assets/images/Solutions/Notifications_diagram.png",
      factor_logo: "/assets/images/factor_logo.png",
      left_img: "/assets/images/Solutions/bubble-4-left.png",
      top_img: "/assets/images/Solutions/bubble-4.png",
      right_img: "/assets/images/Solutions/bubble-4-right.png",
      comment:
        "The notification system enables the broadcasters and content providers to build information arriving from various internal or external sources and distribute messages to specified target platforms.",
      items: [
        {
          img: "/assets/images/Solutions/section_1_item_1.png",
          title: "Content Promotion",
          check_mark: `<span style="color: #06477E !important;")>&#10003</span>`
        },
        {
          img: "/assets/images/Solutions/section_1_item_2.png",
          title: "Life-saving Information",
          check_mark: `<span style="color: #06477E !important;")>&#10003</span>`
        },
        {
          img: "/assets/images/Solutions/section_1_item_3.png",
          title: "Dynamic Notification",
          check_mark: `<span style="color: #06477E !important;")>&#10003</span>`
        }
      ]
    },
    {
      title: "ATSC 3.0 Management",
      description:
        "O-Element ATSC 3.0 Management interactive solution enables application interactivity for ATSC 3.0 systems. The solution provides the necessary tools to create applications quickly and deploy in a hybrid environment, enabling monitizatoin and quick ROI.  The solution supports features such as datacasting for enabling bandwidth usage for purposes beyond broadcast delivery, as well as content replacement and logic to increase ad revenue for targeted advertisement.  The solution can be fully integrated with external alert management systems.  The solution provides all aspects of interactive application management from creation and packaging to signaling and delivery over broadband, broadcast or hybrid.",
      background: "/assets/images/Solutions/Connect.png",
      center_img: "/assets/images/Solutions/ATSC3_diagram.png",
      factor_logo: "/assets/images/factor_logo.png",
      left_img: "/assets/images/Solutions/bubble-5-left.png",
      top_img: "/assets/images/Solutions/bubble-5.png",
      right_img: "/assets/images/Solutions/bubble-5-right.png",
      comment:
        "OpenZNet built the very first complete interactive environment from an ATSC3.0 compliant client software stack, to application framework and various applications running on all participating CE manufacturers.",
      items: [
        {
          img: "/assets/images/Solutions/section_1_item_1.png",
          title: "New Market Opportunities",
          check_mark: `<span style="color: #06477E !important;")>&#10003</span>`
        },
        {
          img: "/assets/images/Solutions/section_1_item_2.png",
          title: "Grow Market",
          check_mark: `<span style="color: #06477E !important;")>&#10003</span>`
        },
        {
          img: "/assets/images/Solutions/section_1_item_3.png",
          title: "Fast Time-to-Market",
          check_mark: `<span style="color: #06477E !important;")>&#10003</span>`
        }
      ]
    },
    {
      title: "Application Management",
      description:
        "O-Element Appliction Management solution provides new market opportunities by providing intuitive drag-and-drop tools to generate interactive applications for any type of content.  Among many features, the solution enables synchronization among different delivery mechanisms such as broadcast and broadband to create additional viewing real estate to monitize; in addition, the same tool can create different specialized applications per target platforms.",
      background: "/assets/images/Solutions/Connect.png",
      center_img: "/assets/images/Solutions/Apps_diagram.png",
      factor_logo: "/assets/images/factor_logo.png",
      left_img: "/assets/images/Solutions/bubble-3-left.png",
      top_img: "/assets/images/Solutions/bubble-2.png",
      right_img: "/assets/images/Solutions/bubble-3-right.png",
      comment:
        "One dashboard to build an interactive application using drag-and-drop tools, customize, and schedule release without needing to talk to a developer.",
      items: [
        {
          img: "/assets/images/Solutions/section_1_item_1.png",
          title: "Ad-Revenue Growth",
          check_mark: `<span style="color: #06477E !important;")>&#10003</span>`
        },
        {
          img: "/assets/images/Solutions/section_1_item_2.png",
          title: "Engaged Clients",
          check_mark: `<span style="color: #06477E !important;")>&#10003</span>`
        },
        {
          img: "/assets/images/Solutions/section_1_item_3.png",
          title: "Easy to Use Dashboard",
          check_mark: `<span style="color: #06477E !important;")>&#10003</span>`
        }
      ]
    },
    {
      title: "Content Management",
      description:
        "O-Element Content Management Solution unifies diverse environments into a single ingest, storage, and distribution system, while providing seamless distribution to different target platforms. The solution utilizes a robust and proven technology that is highly customizable to meet the growing needs of our clients and partners. Among many features, the solution enables publishing specific content based on target-device, geolocation, application, website, schedule, social media and personal profiles.",
      background: "/assets/images/Solutions/Connect.png",
      center_img: "/assets/images/Solutions/cms_diagram.png",
      factor_logo: "/assets/images/factor_logo.png",
      left_img: "/assets/images/Solutions/bubble-2-left.png",
      top_img: "/assets/images/Solutions/bubble-3.png",
      right_img: "/assets/images/Solutions/bubble-2-right.png",
      comment:
        "The solution can notify website changes in less than five seconds avoiding stale content and saving bandwidth in the process with client side rendering.",
      items: [
        {
          img: "/assets/images/Solutions/section_1_item_1.png",
          title: "Targeted Publishing",
          check_mark: `<span style="color: #06477E !important;")>&#10003</span>`
        },
        {
          img: "/assets/images/Solutions/section_1_item_2.png",
          title: "Reduced Operational Costs",
          check_mark: `<span style="color: #06477E !important;")>&#10003</span>`
        },
        {
          img: "/assets/images/Solutions/section_1_item_3.png",
          title: "Better Performance",
          check_mark: `<span style="color: #06477E !important;")>&#10003</span>`
        }
      ]
    },
    {
      title: "Website Management",
      description:
        "O-Element Website Management gives you complete control over creation, publishing, and presentation of your content to meet the daily challenges of your unique environment. The solution utilizes state of the art client-side rendering backed by large organizations to minimize bandwidth usage. Among many features, the solution enables machine learning techniques to adjust layout and content based on the usage; in addition it enables personalization for increased customer satisfaction and recurring website visits.",
      background: "/assets/images/Solutions/Connect.png",
      center_img: "/assets/images/Solutions/Website_diagram.png",
      factor_logo: "/assets/images/factor_logo.png",
      left_img: "/assets/images/Solutions/bubble-1-right.png",
      top_img: "/assets/images/Solutions/bubble.png",
      right_img: "/assets/images/Solutions/bubble-1-left.png",
      comment:
        "It is easy to change  layout of websites built with our tools without forcing a reload in less than a minute.",
      items: [
        {
          img: "/assets/images/Solutions/section_1_item_1.png",
          title: "Dynamic Layout Change",
          check_mark: `<span style="color: #06477E !important;")>&#10003</span>`
        },
        {
          img: "/assets/images/Solutions/section_1_item_2.png",
          title: "Personalized Content",
          check_mark: `<span style="color: #06477E !important;")>&#10003</span>`
        },
        {
          img: "/assets/images/Solutions/section_1_item_3.png",
          title: "Reduce Bandwidth Usage",
          check_mark: `<span style="color: #06477E !important;")>&#10003</span>`
        }
      ]
    }
  ]
};

export const about_page = {
  sections: [
    {
      img: "/assets/images/AboutUs/Group.png",
      title: "Our Team",
      description:
        "We are a group of professionals,  who believe in creating elegantly-designed and easy-to-use software. Our team has worked together since early 2000s in various organizations and coeleced together in early 2014 to build the company.  Our primary focus is to create high quality and customizable solutions, while satisfying our client’s needs. We believe in building unique solutions that provides an advantage for our clients to set themselves apart from all others."
    },
    {
      img: "/assets/images/AboutUs/Book.png",
      title: "Backstory",
      description:
        "OpenZNet is rooted in the standarization and consulting. In light of our technologically advancing world our team built the world’s first end-to-end ATSC 3.0 media delivery system.  The system was built on the latest technology in an agile development process to adapt to the needs of the new environemnt quickly.  The system created the first unified media system managing content, information, websites, and applications  delivered over a hybrid broadband and broadcast environment to a all targetted platforms."
    }
  ]
};

export const coverflow = [
  {
    title: "Cobalt",
    img: "/assets/images/Products/Cobalt.png",
    overlay: "/assets/images/Products/cobalt-icon.png",
    description:
      "Elevate your small business with our cloud-based network of displays accessible from tip of your finger. Enhance your local presence, strengthen community ties, and engage customers for thriving in your local market."
  },
  {
    title: "Administrator",
    img: "/assets/images/Products/Adminstrator-561x587.png",
    overlay: "/assets/images/Products/section-6-icon.png",
    description:
      "Manages websites and configurations for applications; manages users and subscribers. Having a single dashboard reduces operational costs."
  },
  {
    title: "Announcer",
    img: "/assets/images/Products/Announcer-561x587.png",
    overlay: "/assets/images/Products/section-4-icon.png",
    description:
      "Ingests information and sends notifications and alerts to targeted users based on business criteria such as: geolocation, target platform or user profile. Dashboard displays increased value-added services and customer take rate."
  },
  {
    title: "Collector",
    img: "/assets/images/Products/Collector-561x587.png",
    overlay: "/assets/images/Products/section-3-icon.png",
    description:
      "Collects all actions performed and provides the information for viewing or reporting to an external analytic system. Dashboard provides insight on how the tools and websites are used to increase revenue."
  },
  {
    title: "Compositor",
    img: "/assets/images/Products/Compositor-561x587.png",
    overlay: "/assets/images/Products/section-7-icon.png",
    description:
      "Schedules, packages, and delivers applications over broadcast and broadband to companion devices. Dashboard consolodates external dependencies."
  },
  {
    title: "Creator",
    img: "/assets/images/Products/Creator-561x587.png",
    overlay: "/assets/images/Products/section-5-icon.png",
    description:
      "Enables users to build applications quickly and dynamically by using a simple drag-and-drop interface.  Dashboard provides new market opportunities by creating new applications to try in your market."
  },
  {
    title: "Editor",
    img: "/assets/images/Products/Editor-561x587.png",
    overlay: "/assets/images/Products/section-2-icon.png",
    description:
      "Provides a rich editing interface to modify content, code and associated metadata, while validating user input.  Dashboard provides value-added features for quick content editing."
  },
  {
    title: "Organizer",
    img: "/assets/images/Products/Organizer-561x587.png",
    overlay: "/assets/images/Products/section-1-icon.png",
    description:
      "Easy-to-use folder-style interface to create grouping of content easily shared with other users and applications.  Dashboard increases workflow efficiency by providing an intuitive standardized user interface."
  },
  {
    title: "Personalizer",
    img: "/assets/images/Products/Personalizer-561x587.png",
    overlay: "/assets/images/Products/section-8-icon.png",
    description:
      "Enables content replacement based on sets of business logic and rules utilizing users preferences.  Additionally, it interfaces with external ad decision engines.  Dashboard provides value-added services and take rate for advertisers."
  },
  // {
  //   title: "Products",
  //   img: "/assets/images/Products/Creator-561x587.png",
  //   overlay: "/assets/images/Products/section-9-icon.png",
  //   description:
  //     "Provides an easy-to-use dashboard for creating and managing all your content related needs."
  // }
];

export const approach = {
  title: "Our Approach",
  sub_title:
    "We strive to give each of our solutions a unique O-Factor that goes beyond what is expected"
};

export const factor = {
  factor_logo: "/assets/images/factor_logo.png",
  logo_title: "factor",
  logo_sub_title: "ō ˈfaktər",
  title: "noun",
  sub_title:
    "The “wow” factor. The elements that exceed one's expectations which can cause them to exclaim “Oh.”"
};

export const simplify = {
  img: "/assets/images/Home/Simplify/bg-resized.png",
  title: "Simplify",
  mask: "rgba(101, 96, 95, 0.25)",
  description:
    "See how our products can streamline your workflow by adopting new proven technologies backed by large organizations."
};

export const underneath = {
  title: ' Our state-of-the-art multimedia management and communication platform organizes, targets,  and enhances your content.',
}

export const solution = {
  title: 'OUR SOLUTIONS',
  description: '',
};

export const quote = {
  title:
    "“Never accept the proposition that just because a solution satisfies a problem, that it must be the only solution.”",
  sub_title: "Raymond E. Feist"
};
export const solutions = {
  title: "Markets",
  sub_title:
    "See how to bring in value-added services,  reduce operation costs, increase rervenu, and improve work-flow effeciencies in your markets."
};

export const why_choose_us = {
  title: "Why Choose Us",
  items: [
    {
      img: "/assets/images/Home/WhyChooseUs/Integrity.png",
      color: "#388B91",
      title: "INTEGRITY",
      subtitle:
        "We take pride in delivering what is promised, being transparent and staying true to our core-beliefs - serving our clients."
    },
    {
      img: "/assets/images/Home/WhyChooseUs/Experience.png",
      color: "#242D3C",
      title: "EXPERIENCE",
      subtitle:
        "Collectively the team has had over 200 years in embedded software, video streaming and content management systems."
    },
    {
      img: "/assets/images/Home/WhyChooseUs/Agility.png",
      color: "#BC6946",
      title: "AGILITY",
      subtitle:
        "Adopting the latest technology has enabled us to be one of the most agile companies in our field from creation to production."
    }
  ]
};

export const KPI = [
  {
    img: "assets/images/Vector.png",
    text: "Leader in Market Software Development"
  },
  {
    heading: "Over",
    number: "200",
    text: "Years of Combined Experience"
  },
  {
    img: "assets/images/Hourglass.png",
    text: "Time Saving Techniques"
  }
];

export const channel_section = {
  channels: [
    {
      img: "assets/images/Home/Products/Administrator.png",
      text: "Administrator"
    },
    {
      img: "assets/images/Home/Products/Announcer.png",
      text: "Announcer"
    },
    {
      img: "assets/images/Home/Products/Collector.png",
      text: "Collector"
    },
    {
      img: "assets/images/Home/Products/Editor.png",
      text: "Editor"
    },
    {
      img: "assets/images/Home/Products/Compositor.png",
      text: "Compositor"
    },
    {
      img: "assets/images/Home/Products/Personalizer.png",
      text: "Personalizer"
    },
    {
      img: "assets/images/Home/Products/Streamer.png",
      text: "Streamer"
    },
    {
      img: "assets/images/Home/Products/Creator.png",
      text: "Creator"
    },
    {
      img: "assets/images/Home/Products/Organizer.png",
      text: "Organizer"
    }
  ]
};
export const service_section = {
  title: "Let us Help",
  btn_label: "LEARN MORE",
  header_background: "assets/images/Services/firstscreen_bg.png",
  background: "assets/images/Home/Services/Connect.png",
  services: [
    {
      title: "Technical Consultancy",
      img: "assets/images/Home/Services/serviceLogo.png",
      description:
        "Are you having difficulties  integrating new technologies? Is your team overloaded with day to day activities and you need help integrating new products or workflows?"
    },
    {
      title: "Software Development",
      img: "assets/images/Home/Services/serviceLogo.png",
      description:
        "Are you having difficulties  integrating new technologies? Is your team overloaded with day to day activities and you need help integrating new products or workflows?"
    },
    {
      title: "Program Management",
      img: "assets/images/Home/Services/serviceLogo.png",
      description:
        "Are you having difficulties  integrating new technologies? Is your team overloaded with day to day activities and you need help integrating new products or workflows?"
    },
    {
      title: "UX Design",
      img: "assets/images/Home/Services/serviceLogo.png",
      description:
        "Are you having difficulties  integrating new technologies? Is your team overloaded with day to day activities and you need help integrating new products or workflows?"
    },
    {
      title: "Integration",
      img: "assets/images/Home/Services/serviceLogo.png",
      description:
        "Are you having difficulties  integrating new technologies? Is your team overloaded with day to day activities and you need help integrating new products or workflows?"
    }
  ]
};

export const contact_us = {
  title: "Contact us"
};

export const contact_form = {
  btn_label: "SUBMIT",
  placeholder_name: "Name",
  placeholder_lastname: "Lastname",
  placeholder_email: "Email",
  placeholder_company: "Company",
  placeholder_message: "Message"
};

export const tools_menu = {
  title: 'Tools Menu',
  menus: [
    { label: 'Announcer', path: 'announcer' },
    { label: 'Editor', path: 'editor' },
    { label: 'Organizer', path: 'organizer' },
    { label: 'Creator', path: 'creator' },
    { label: 'Administrator', path: 'administrator' },
    { label: 'Collector', path: 'collector' },
    { label: 'Emulator', path: 'emulator' },
    { label: 'Compositor', path: 'compositor' },
  ],
}

export const homeProducts = [
  {
    title: "INFORM",
    img: "/assets/images/Home/HomeProducts/Digital Signage-INFORM.jpg",
    description:
      "Our product serves as a dynamic network of displays for businesses, effortlessly showcasing changing menus, service prices, or special promotions. Customers are always informed and engaged, whether it’s a cafe highlighting daily specials, a salon listing new treatments, or a store announcing sales. The easy-to-use tool ensures businesses can quickly communicate essential details, boosting customer awareness and satisfaction."
  },
  {
    title: "ENGAGE",
    img: "/assets/images/Home/HomeProducts/ENGAGE.png",
    description:
      "Our product offers an interactive platform where customers can actively participate in a brand’s story. After enjoying a product or service, they can easily upload images or share experiences, giving them a voice and fostering a deeper connection with the business. This community-driven approach amplifies genuine user testimonials and transforms customers into brand advocates, enriching the overall brand story."
  },
  {
    title: "ENTERTAIN",
    img: "/assets/images/Home/HomeProducts/Digital Signage-ENTERTAIN.png",
    description:
      "Our product is an entertainment hub, presenting a curated mix of light-hearted content, educational snippets, and short engaging videos. As customers wait for their orders, they can watch and learn something new, enriching their experience. This adds value to their wait time and enhances the overall customer experience, leaving a lasting impression."
  }
];
