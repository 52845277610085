import React, { Component } from 'react'
import './Footer-with-button-logo.css'
import { withRouter } from '../../utils/hook'
import { formatDate } from '../../utils/functions'
import { Link } from 'react-router-dom'

class FooterComp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      version_information: {
        date: null,
        version: ''
      }
    }
    this.updateDate = ''
  }

  componentDidMount() {
    const fullPath = window.location.href
    const position = fullPath.indexOf(this.props.router.location.pathname)
    let target = fullPath + 'deployment-time.txt'

    if (this.props.router.location.pathname != '/') target = fullPath.substr(0, position) + '/deployment-time.txt'
    // console.log('target', target )

    // fetch(target)
    //   .then(r => r.text())
    //   .then(timestamp => {
    //     // console.log("This is timestamp: ", timestamp);
    //     this.updateDate = timestamp
    //     var d = new Date(timestamp)
    //     this.setState({
    //       version_information: {
    //         date: d.toLocaleDateString() + ' ' + d.toLocaleTimeString(),
    //         version: '0.0.0.0'
    //       }
    //     })
    //   })
    //   .catch(error => console.log('parsing failed', error))
  
    this.setState({
      version_information: {
        date: formatDate(new Date()),
        version: '0.0.0.0'
      }
    })
  }

  render() {
    // console.log(window.location.href, this.props.router.location.pathname)
    return (
      <div className="content">
        <div id="myFooter">
          <div className="container-footer">
            <div className="row" style={{ flexDirection: 'nowrap', textAlign: 'center' }}>
              <div className="mt-4">
                <Link to="/">
                  Home
                </Link>
              </div>
              <div className="mt-4">
                <Link to="/cobalt">
                  Cobalt
                </Link>
              </div>
              <div className="mt-4">
                <Link to="/blog">
                  Blog
                </Link>
              </div>
              <div className="mt-4">
                <Link to="/solutions">
                  Solutions
                </Link>
              </div>
              <div className="mt-4">
                <Link to="/products">
                  Products
                </Link>
              </div>
              <div className="mt-4">
                <Link to="/services">
                  Services
                </Link>
              </div>
              <div className="mt-4">
                <Link to="/ocircle">
                  O-Circle
                </Link>
              </div>
              <div className="mt-4">
                <Link to="/about_us">
                  About Us
                </Link>
              </div>
              {/* <div className="col-sm-3"> */}
              {/* <div className="social-networks">
                  <Link to="#" className="twitter">
                    <i className="fa fa-twitter">
                      <FontAwesomeIcon icon={faTwitter} />
                    </i>
                  </Link>

                  <Link to="#" className="facebook">
                    <i className="fa fa-facebook">
                      <FontAwesomeIcon icon={faFacebook} />
                    </i>
                  </Link>
                  <Link to="#" className="google">
                    <i className="fa fa-google-plus">
                      <FontAwesomeIcon icon={faInstagram} />
                    </i>
                  </Link>
                </div> */}
              {/* <button type="button" className="btn btn-default">
                  Contact us
                </button> */}
              {/* </div> */}
            </div>
          </div>
          <hr style={{ borderColor: 'lightgray', margin: '1rem 0 0 0' }} />
          <div className="footer-copyright ml-md-4 mr-md-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <p style={{ float: 'left' }}>
              &copy;
              {new Date().getFullYear()} OpenZNet, Inc. All Rights Reserved
            </p>
            <Link to="/privacy-policy">Privacy & Policy</Link>
            <Link to="/terms-of-service">Terms of Service</Link>
            <p className="footer-timestamp" style={{ float: 'right' }}>
              Update on {this.state?.version_information?.date}
            </p>
          </div>
        </div>
        <div style={{ clear: 'both' }} />
      </div>
    )
  }
}

export default withRouter(FooterComp)
